// 通用js方法封装处理

/**
* 参数处理
* @param {*} params  参数
*/
export function tansParams(params) {
  let result = ''
  for (const propName of Object.keys(params)) {
    const value = params[propName];
    var part = encodeURIComponent(propName) + "=";
    if (value !== null && value !== "" && typeof (value) !== "undefined") {
      if (typeof value === 'object') {
        for (const key of Object.keys(value)) {
          if (value[key] !== null && value[key] !== "" && typeof (value[key]) !== 'undefined') {
            let params = propName + '[' + key + ']';
            var subPart = encodeURIComponent(params) + "=";
            result += subPart + encodeURIComponent(value[key]) + "&";
          }
        }
      } else {
        result += part + encodeURIComponent(value) + "&";
      }
    }
  }
  return result
}

// 验证是否为blob格式
export async function blobValidate(data) {
  try {
    const text = await data.text();
    JSON.parse(text);
    return false;
  } catch (error) {
    return true;
  }
}

// 表单重置
export function resetForm(refName) {
  if (this.$refs[refName]) {
    this.$refs[refName].resetFields();
  }
}

// 判断是否为null和undefine
export function isNullVal(v) {
  return v === null || v === undefined ? true : false;
}

// 判断是否为null和undefine和''
export function isEmptyVal(v) {
  return v === "" || v === null || v === undefined ? true : false;
}

/**
 * 表格时间格式化
 */
export function formatDate(cellValue) {
  if (cellValue == null || cellValue == "") return "";
  var date = new Date(cellValue)
  var year = date.getFullYear()
  var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  // var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  // var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  // var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  // return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
  return year + '-' + month + '-' + day
}
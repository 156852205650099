// 银行理财
export default {
    financeBank: {
        title: `
        With the proposal of the carbon peak and neutrality goals and the wide recognition of the domestic ESG concept, 
        many domestic banks have released retail products such as low-carbon and ESG-related wealth management products. Given retail products have a 
        limited level of disclosure, we are unable to conduct systematic "climate-friendly" product screening.  That said, this is an important channel for 
        individual investors to participate in climate investment. 
        Accordingly, we have compiled existing green concept product cases for the reader's reference.
        `,
    },
    financeHead:
    {
        tableTh1: 'Product name',
        tableTh2: 'Product definitions',
        tableTh3: 'Practiced bank and related products',
    },
    financeTable: [{
        id: 1,
        prodName: 'Sustainable deposits',
        prodDefine: 'Funds deposited in green deposits will be used to support green and sustainability-related projects',
        bankAndRelateProd: `
        <p><a href="https://www.sc.com/cn/en/deposits/sucd/" target="_blank">Standard Chartered China – Individual Sustainable Certificates of Deposit</a></p>
        <p><a href="https://www.business.hsbc.com.cn/en-gb/campaigns/sustainable-finance/green-deposits" target="_blank">HSBC - Green Deposits (For Companies)</a></p>
        <p>Bank of China(Shanghai Branch)- Green Deposits (For Companies)</p>`,
        bankList: [{
            name: 'Standard Chartered China',
            link: `<p><a href="https://www.sc.com/cn/en/deposits/sucd/" target="_blank">Individual Sustainable Certificates of Deposit>></a></p>`,
        }, {
            name: 'HSBC',
            link: `<p><a href="https://www.business.hsbc.com.cn/en-gb/campaigns/sustainable-finance/green-deposits" target="_blank"> Green Deposits (For Companies)>></a></p>`,
        }, {
            name: 'Bank of China(Shanghai Branch)',
            link: `<p><a href="https://mp.weixin.qq.com/s/JimT0nbmjs3vZuXDGfUxqw" target="_blank">Green Deposits (For Companies)>></a></p>`,
        }]
    },
    {
        id: 2,
        prodName: 'Green car consumer loans',
        prodDefine: 'Green car consumer loan refers to the consumer loan provided by commercial banks to individuals, families and small and micro enterprises to purchase green vehicles such as energy-saving and new energy vehicles',
        bankAndRelateProd: `
        <a href="https://creditcard.ecitic.com/h5/shenqing/iche/index.html?cls=SJWAPICBCB02&sid=SJWAPPT" target="_blank">
        China CITIC Bank - China CITIC Bank iCar credit card, Tesla owner car consumer loan business</a>`,
        bankList: [{
            name: ' China CITIC Bank',
            link: `<p><a href="https://creditcard.ecitic.com/h5/shenqing/iche/index.html?cls=SJWAPICBCB02&sid=SJWAPPT" target="_blank">China CITIC Bank iCar credit card, Tesla owner car consumer loan business>></a></p>`,
        }]
    },
    {
        id: 3,
        prodName: 'Green building mortgage loans',
        prodDefine: 'Green buildings, passive buildings and prefabricated buildings developed by banks for real estate enterprises belong to the scope of green development loans, and the financing applied for by banks for individuals and households and enterprises to purchase green buildings, passive buildings and prefabricated buildings can be classified as green building consumer credit or green building mortgage loans',
        bankAndRelateProd: `
        <p>Industrial Bank– Green Mortgage</p>
        <p><a href="http://www.masrcb.com/info/1008/2459.htm" target="_blank">
        Ma'anshan Rural Commercial Bank - Green Housing Mortgage Loan</a></p>`,
        bankList: [{
            name: 'Industrial Bank',
            link: `<p><a href="https://www.cib.com.cn/cn/aboutCIB/about/news/2018/20180412_2.html" target="_blank"> Green Mortgage>></a></p>`,
        }, {
            name: "Ma'anshan Rural Commercial Bank",
            link: `<p><a href="http://www.masrcb.com/info/1008/2459.htm" target="_blank">Green Housing Mortgage Loan>></a></p>`,
        }]
    },
    {
        id: 3,
        prodName: 'Green inclusive agriculture and forestry loans',
        prodDefine: 'Commercial banks provide loans to farmers and foresters. Among the domestic green consumer loans, they are mainly manifested in forest rights mortgage loans and ecological agriculture loans. The forest rights mortgage loan is a loan obtained by means of guarantee for the forest, the ownership of forest trees and the right to use forest land, so as to meet the forestry production and operation and other production and living related capital needs of farmers and other entities',
        bankAndRelateProd: `<p>Industrial Bank - Forest Rights mortgage loan</p>`,
        bankList: [{
            name: 'Industrial Bank',
            link: `<p><a href="http://city.ce.cn/news/202112/22/t20211222_7334217.shtml" target="_blank">Forest Rights mortgage loan>></a></p>`,

        }]
    }, {
        id: 5,
        prodName: 'Green credit card',
        prodDefine: 'Commercial banks have launched green credit cards to promote "low-carbon" life and encourage consumers to join the ranks of green environmental protection, advocating green consumption. Green action methods can be divided into: afforestation, purchase of carbon emission reduction, environmental rights protection, etc',
        bankAndRelateProd: `
        <p>Everbright Bank - Green Zero Carbon Credit Card</p>
        <p>China Agriculture Bank - Bank of China Golden Spike Environmental Protection Card</p>`,
        bankList: [{
            name: 'Everbright Bank',
            link: `<p><a href="https://cbeex.com.cn/article/ywzx/tjyzx/txyk/" target="_blank">Green Zero Carbon Credit Card>></a></p>`,

        }, {
            name: 'China Agriculture Bank',
            link: `<p><a href="http://www.acef.com.cn/a/gyb/gyxmtj/2013/1214/238.html" target="_blank">Bank of China Golden Spike Environmental Protection Card>></a></p>`,

        }]
    }, {
        id: 6,
        prodName: 'Green photovoltaic loan',
        prodDefine: 'Green photovoltaic loan is a specially customized loan product to support household photovoltaic power generation, mainly used to pay for the cost of complete sets of equipment for photovoltaic power plants, the loan is based on household income, state subsidies and surplus electricity grid income as the main source of repayment',
        bankAndRelateProd: `

        <p><a href="http://www.jsbchina.cn/CN/gsyw/cgsyw/gxmrzl/541.html" target="_blank">Bank of Jiangsu - Photovoltaic Loan</a></p>
      
        `,
        bankList: [
            {
                name: 'Bank of Jiangsu',
                link: `<p><a href="http://www.jsbchina.cn/CN/gsyw/cgsyw/gxmrzl/541.html" target="_blank"> Photovoltaic Loans>></a></p>`,
            }
        ]
    }, {
        id: 7,
        prodName: 'Personal Carbon Account',
        prodDefine: "Financial institutions track users' carbon emission reduction behavior through financial services, quantify users' carbon emission reduction behavior, form carbon account balances, and then link carbon account balances with financial services and user rights and interests, and even convert carbon account balances into real cash in the future",
        bankAndRelateProd: `
        <p><a href="http://www1.ccb.com/cn/v3/include/notice/upload/20220829_1661767732/20220829182230773852.pdf" target="_blank">China Construction Bank - Carbon Ledger</a></p>
        <p>CITIC Bank - CITIC Carbon Account</p>
        <p>Ping An Bank - Personal Carbon Account</p>
        <p>Qujiang Rural Commercial Bank - Personal Carbon Account</p>`,
        bankList: [{
            name: 'China Construction Bank',
            link: `<p><a href="http://www1.ccb.com/cn/v3/include/notice/upload/20220829_1661767732/20220829182230773852.pdf" target="_blank">Carbon Ledger>></a></p>`,
        }, {
            name: 'CITIC Bank',
            link: `<p><a href="https://mp.weixin.qq.com/s/C1YBiVYvM1HPzmXb6h80sg" target="_blank">CITIC Carbon Account>></a></p>`,

        }, {
            name: 'Ping An Bank',
            link: `<p><a href="https://mp.weixin.qq.com/s/GyUL2Fa1Vmn7qWuGVmDMbg" target="_blank">Personal Carbon Account>></a></p>`,

        }, {
            name: 'Qujiang Rural Commercial Bank',
            link: `<p><a href="https://zj.zjol.com.cn/news.html?id=1718449" target="_blank">Personal Carbon Account>></a></p>`,

        }]
    },
    ],

    financeTab: {
        tab1: 'Funds',
        tab2: 'Bonds',
        tab3: 'Bank Retail Products'
    },
    financeFilter: {
        title: 'Filter:',
        methodBtn: 'Methodology',
        clearBtn: 'Clear All',
        funsType: 'Fund Type',
        manage: 'Fund Manager',
        standard: 'Standard',
        selfSearch: 'Screening',
        okBtn: 'Confirm',
        standardDiff: 'Learn more about the difference',
        fundDiffBtn: 'Learn more about the difference',
        bondDiffBtn: 'Learn more about the difference',
        issuerObjBtn: 'Learn more about the difference',
        issuer: 'Targeted Investor',
        bondType: 'Bond type',
        placeholder: 'Please select a fund manager',
        diyFundsTip1: 'Proportion of fair value of climate solutions companies that are equal or greater than',
        diyFundsTip2: '50%~100%',
        diyFundsTip3: 'Proportion of fair value of fossil fuel related holdings account for less than',
        diyFundsTip4: '0%~5%',
        diyBondTip1: 'Proportion of climate-friendly funding uses is equal or higher than',
        diyBondTip2: '50%~100%',
    },
    financeFundsTable: {
        tableTh1: 'Serial number',
        tableTh2: 'Code',
        tableTh3: 'Fund Managers',
        tableTh4: 'Funds',
        // tableTh5: `<div>是否为气候友好型基金</div><span>（FFF标准）</span>`,
        tableTh6: 'Funds Type',
        tableTh7: 'Offical website',
        tableTh8: 'Proportion of fair value of climate solutions ',
        tableTh9: `<div>Proportion of fair value of fossil fuel related holdings</div><span>(refer to SFDR standard)</span>`,
        tableTh10: `<div>Proportion of fair value of fossil fuel related holdings</div><span>(refer to Fossil Free Funds criteria)</span>`,
    },
    financeBondsTable: {
        tableTh1: 'Serial number',
        tableTh2: 'Bond codes',
        tableTh3: 'Bond Abbreviation',
        tableTh9: 'Bond Type',
        tableTh4: 'Targeted Investors',
        // tableTh5: `<div>是否为气候友好型债券</div><span>（SFDR）</span>`,
        tableTh6: 'Proportion of climate-friendly funding uses',
        tableTh7: `<div>Proportion of fossil fuel related funding uses</div><span>(refer to SFDR criteria)</span>`,
        tableTh8: `<div> Proportion of fossil fuel related funding uses</div><span>(refer to Fossil Free Funds criteria)</span>`,
    },
    financeFooter: {
        tip: 'If you are interested in our climate-friendly investment products, or have suggestions or comments, please contact us!',
        email: 'Email',
        wechat: 'Wechat',
        deadLine: '',
    },
    // 基金类别
    financeFundsType: [
        { name: "Index Fund", code: 1 },
        { name: "Stock Fund", code: 2 },
        { name: 'Mixed Fund', code: 3 }
    ],
    // 选择标准
    financeSelectStandard: [
        { name: "Standard 1 (Best-in-class)", code: 1 },
        { name: "Standard 2", code: 2 },
    ],
    //发行对象
    financeIssueObject: [
        { name: "Member of National Interbank Bond Market", code: 1 },
        { name: "Qualified Institutional Investor", code: 2 },
        { name: "Domestic legal and natural persons", code: 3 },
    ],

    // 错误消息
    financeError: {
        error1: 'Please enter a numerical value',
        error2: 'Please enter a number between 50-100',
        error3: 'Please enter a number between 0-5',
    },

    //债券类别
    financeBondType: [
        { name: "Enterprise Bond", code: 1 },
        { name: "Corporate Bond", code: 2 },
        { name: "Short-term Commercial Paper", code: 3 },
        { name: "Medium Term Note", code: 4 },
        { name: "Government-backed Bond", code: 5 },
    ],
}
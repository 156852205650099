// 方法学

export default {
    methodsTop: {
        title: "气候友好型投资产品筛选方法学"
    },
    methodsSection1: {
        title: "1. 确定基金/债券筛选范围",
        titleH3: '1. 如何确定基金/债券筛选范围',
        content: `
        <p>项目组基于公开信息渠道对中国大陆市场的公募基金和债券产品进行了初步定位筛选。
        对于公募基金，我们选取了10个与“气候友好”相关的关键词来对市场上的公募基金进行初步定位，这10个关键词包括：
        新材料、低碳、碳中和、气候、新能源、绿色、ESG、可持续、责任和环保。另外，我们在确认公募基金初步筛选范围时，
        我们对由于交易费用等因素造成的名称差异的基金进行去重处理，对由于基金成立不足一年或者期间未持有股票资产等原因而未公
        开披露股票持仓的基金进行了排除。</p>
        <p>对于债券产品，我们以被中央结算公司“中债实质绿”项目收录的债券为初始筛选范围，因为这些债券至少符合《绿色债券支持项目目录》、
        《绿色债券发行指引》、《绿色债券原则》、《气候债券分类方案》其中的一项。</p>
        <p>我们的筛选范围覆盖了上百支主动策略型或被动指数型公募基金以及上千支绿色债券。</p>
        `
    },
    methodsSection2: {
        title: '2. 如何分析基金/债券',
        titleH3: '2. 如何分析一支基金/债券',
        content: `<p>对于任何一支公募基金，我们会检验其持仓的任一家公司各项主营业务是否为促进减缓气候变化或适应气候变化的相关经
        济活动，并判断是否存在来自化石燃料相关产业的收入。对于上市公司，我们的分析师主要依据其最新年度报告中对公司主营业务的分析和
        数据披露进行判断。结合我们对基金持仓公司的分析结果、标的公司公允价值、基金持仓情况，我们会计算并披露每支公募基金的“气候友
        好”相关和“化石燃料”相关持仓情况。</p>
        <p>对于任何一支债券，我们会检验其资金用途是否用于促进减缓气候变化或适应气候变化的相关经济活动以及是否流向了化石燃料相关产业资产。
        我们的分析师主要依据其债券募集说明书中对募集资金用途的说明进行判断，必要时也参考了债券年度报告、债券评级报告等文件信息。
        我们会披露并计算每支债券所募集的资金流向气候友好经济活动的比例以及是否支持化石燃料产业。</p>`
    },
    methodsSection3: {
        title: '3. 业务气候友好分析',
        titleH3: '3. 如何判断上市公司主营业务/债券资金用途是否气候友好',
        content: `<p>我们考察了全球前沿的气候友好经济活动判断方法，重点关注基于与《巴黎协定》目标一致的情景分析方法对经济活动和减碳技术
        进行筛选判断的前沿成果。我们广泛参考了包括由领先的气候研究组织<a href="https://www.drawdown.org" target="_blank" class="dl-a">Project Drawdown<a>推出的Drawdown List在内的海外前沿成果，
        并结合我们对中国国情和气候友好技术的理解，定义了55个行业标签的作为本项目的气候友好经济活动目录。</p>
        <p>本项目中，我们将气候友好业务收入占比达到营业总收入50%以上的上市公司定义为“气候解决方案”公司。</p>
        `
        , btn1: '附录1：气候友好经济活动目录（55项）',
    },
    methodsSection4: {
        title: '4. 化石燃料分析',
        titleH3: '4. 如何判断上市公司主营业务/债券资金用途是否涉及化石燃料相关行业',
        content: `<p>在化石燃料分析方面，我们设定了两种标准对化石燃料相关的经济活动进行定义，相比于标准二，标准一额外要求了“炼焦煤生产”、“为油气勘探提供服务和设备”、
        “火力发电和火电销售、输配业务”，囊括的范围更广，剔除标准更严格，为了筛选出更符合气候友好的投资产品，我们优先推荐参考更严格的标准一。两种标准的对比可参考附录2。</p>
        <p>标准一：我们参考<a href="https://fossilfreefunds.org" target="_blank" class="dl-a">Fossil Free Funds<a> 以及海内外前沿的方法学，将以下几类经济活动定义为化石燃料相关经济活动：</p>
        <p>• 化石燃料（炼焦煤、热煤、石油和天然气）的勘探、生产、制造、加工、销售、存储和运输；</p>
        <p>• 为化石燃料勘探活动提供设备和服务；</p>
        <p>• 火力发电和火电销售、输配业务；</p>
        <p>标准二：参考欧盟的《可持续金融信息披露条理》（SFDR）</p>
       `
        , btn1: '附录2： 化石燃料筛选标准对比',
    },
    methodsSection5: {
        title: '5. 评价气候友好产品',
        titleH3: '5. 如何评价一支基金/债券是否为气候友好型产品',
        content: `本项目中，我们定义的“气候友好型”公募基金是指50%或以上的所投市值由“气候解决方案”上市公司构成，同时只有不超过5%的所投市值上市公司涉及化石燃料行业相关收入。我们定义的“气候友好型”债券是指50%或以上的募集资金用于气候友好经济活动，同时完全不用于化石燃料相关行业。`
    },
}

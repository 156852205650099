import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie'

import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang en
import elementZhCNLocale from 'element-ui/lib/locale/lang/zh-CN'// element-ui lang cn

import enLocale from './en'
import zhLocale from './zh'
import homeEnLocale from './homeEn'
import homeZhCNLocale from './homeZh_CN'
import financeEnLocale from './financeEn'
import financeZhCNLocale from './financeZh_CN'
import methodsEnLocale from './methodsEn'
import methodsZhCNLocale from './methodsZh_CN'
import FAQEnLocale from './FAQEn'
import FAQZhCNLocale from './FAQZh_CN'
import appendixZhCNLocale from './appendixZh_CN'
import appendixEnLocale from './appendixEn'
import aboutZhCNLocale from './aboutZh_CN'
import aboutEnLocale from './aboutEn'
import legalEnLocale from "./legalEn"
import legalZhCNLocale from "./legalZh_CN"
Vue.use(VueI18n)

const messages = {
    en_us: {
        ...enLocale,
        ...elementEnLocale,
        ...homeEnLocale,
        ...financeEnLocale,
        ...methodsEnLocale,
        ...FAQEnLocale,
        ...appendixEnLocale,
        ...aboutEnLocale,
        ...legalEnLocale,
    },
    zh_cn: {
        ...zhLocale,
        ...elementZhCNLocale,
        ...homeZhCNLocale,
        ...financeZhCNLocale,
        ...methodsZhCNLocale,
        ...FAQZhCNLocale,
        ...appendixZhCNLocale,
        ...aboutZhCNLocale,
        ...legalZhCNLocale,
    }
}

const i18n = new VueI18n({
    globalInjection: true,
    // 设置语言 选项 en | zh
    locale: Cookies.get('language') || 'en_us',
    // 设置文本内容
    messages
})

export default i18n

// 方法学

export default {
    methodsTop: {
        title: "Methodology for the screening of climate-friendly investment products"
    },
    methodsSection1: {
        title: "1. Screening Range",
        titleH3: '1. How to determine the fund/bond screening range',
        content: `
        <p>The project team conducted a preliminary screening of publicly offered funds and bond products in the mainland of China market based on publicly available information sources. 
        For publicly offered funds, we selected 10 keywords related to "climate friendly" to initially screen publicly offered funds in the market, these keywords include: new materials, 
        low carbon, carbon neutral, climate, new energy, green, ESG, sustainable, responsible, and environmental protection. Besides, in the process of confirming the initial screening 
        scope of publicly offered funds, we remove the duplicates of funds with different names as the result of different management fee structures as well as funds that have not 
        publicly disclosed their equity positions due to reasons such as the fund being established for less than one year or not holding equity assets during that period.</p>

        <p>For bond products, we initially screened for bonds that are included in the China Central Depository 
        & Clearing Co., Ltd. (CCDC) "China’s Bond Substantial Green" project, as these bonds meet at least one of the following 
        documents: Green Bond Endorsed Projects Catalogue, Green Bonds Issuance Guidelines, Green Bond Principles, and Climate Bonds 
        Taxonomy.</p>
        <p>Our screening covers hundreds of actively managed funds or passive indexed publicly offered funds, and thousands of green bonds.</p>
        `
    },
    methodsSection2: {
        title: '2. How to Analyze',
        titleH3: '2. How to analyze a fund/bond',
        content: `
        <p>For any publicly offered fund, we assessed whether the economic activities of invested company’s major business are related to facilitation of climate change mitigation or 
        adaptation and determine whether its income generated from fossil fuel-related industries. For listed companies, our analysts relied on analysis of the company’s major business 
        from its latest annual report and data disclosure. We calculated and disclosed the holding positions of "climate-friendly" and "fossil fuel" performance of each mutual fund by 
        considering of the analytical result of the fund’s invested companies, the fair value of the underlying companies as well as the fund's holdings.
        </p>
        <p>For any publicly offered fund, we assessed whether the economic activities of invested company’s major business are related to facilitation of climate change mitigation or 
        adaptation and determine whether its income generated from fossil fuel-related industries. For listed companies, our analysts relied on analysis of the company’s major business 
        from its latest annual report and data disclosure. We calculated and disclosed the holding positions of "climate-friendly" and "fossil fuel" performance of each publicly offered 
        fund by considering of the analytical result of the fund’s invested companies, the fair value of the underlying companies as well as the fund's holdings.</p>`
    },
    methodsSection3: {
        title: "3. Climate-Friendly Economic Activities",
        titleH3: "3. How to determine if a listed company's main business/bond funding is climate friendly",
        content: `
        <p>We examined the global frontier approaches to recognizing climate-friendly economic activity, focusing on cutting-edge outcomes that screen economic activities and carbon reduction technologies based on scenario analysis methods consistent with the objectives of the Paris Agreement. 
        We extensively referred overseas cutting-edge research including the Drawdown List launched by <a href="https://www.drawdown.org" target="_blank" class="dl-a">Project Drawdown<a>, a leading climate-related research organization, combined with our understanding of the situation in China, resulting in a catalogue of 55 items for climate-friendly economic activities.</p>
        <p>For this project, we defined listed companies as "climate solution" companies if the revenue from climate friendly 
        business accounts for more than 50% of the total business revenue.</p>`
        , btn1: 'Appendix 1: Industry labels from climate-friendly economic activities list',
    },
    methodsSection4: {
        title: "4. Fossil Fuel-Related Industries",
        titleH3: "4. How to determine whether a listed company's main business/use of bond funds are associated with fossil fuel-related industries",
        content: `<p>In terms of fossil fuel analysis, we have set two criteria for defining fossil fuel-related economic activities, and the comparison of the two standards can be 
        found in Appendix 2. Compared with Standard 2, Standard 1 requires "coking coal production", "services and equipment for oil and gas exploration", "thermal power generation and thermal power 
        sales, transmission and distribution business "In order to screen out more climate-friendly investment products, we give priority to the more stringent standard 1.<p>
        
        <p>Standard 1: We refer to <a href="https://fossilfreefunds.org" target="_blank" class="dl-a">Fossil Free Funds<a> and the international frontiers and define the following types of economic activities as fossil fuel-related economic activities:</p>
        <p>• Exploration, production, manufacture, processing, marketing, storage and transport of fossil fuels (coking coal, thermal coal, oil and gas); </p>
        <p>• Provision of equipment and services for fossil fuel exploration activities;</p>
        <p>• Thermal power generation and thermal power sales, transmission and distribution business;</p>
        <p>Standard 2: Reference to the EU's Sustainable Finance Disclosure Regulations (SFDR)</p>
     `
        , btn1: 'Appendix 2: Comparison of fossil fuel screening standard',
    },
    methodsSection5: {
        title: '5. Climate-Friendly Product',
        titleH3: '5. How to evaluate whether a fund/bond is climate-friendl',
        content: `
        In this project, we defined "climate-friendly" mutual fund as the ones of which 50% or more of the market 
        capitalization of the portfolio consists of listed "Climate solution" companies, while no more than 5% of the market 
        capitalization of the invested companies generated revenue from fossil fuel-related industries. We defined 
        "climate-friendly" bond as the ones of which 50% or more proceeds are used for climate friendly economic activities 
        and proceeds are not entirely used for fossil fuel-related industries.
        `
    },
}

<template>
  <div>
    <template v-for="(item, index) in options"> 
      <template v-if="values.includes(String(item.code))">
        <span
          :key="item.code"
          :index="index"
          >{{ item.name }}</span
        >
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: "DictTag",
  props: {
    options: {
      type: Array,
      default: null,
    },
    value: [Number, String, Array],
  },
  computed: {
    values() {
      if (this.value !== null && typeof this.value !== 'undefined') {
        return Array.isArray(this.value) ? this.value : [String(this.value)];
      } else {
        return [];
      }
    },
  },
  // created(){
  //   this.test()
  // },
  // methods:{
  //   test(){
  //     console.log(this.value,"测试")
  //     console.log(this.values)
  //   }
  // },
};
</script>
<style scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
</style>
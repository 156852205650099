
export default {
    // 银行理财
    financeBank: {
        title: `随着双碳目标的提出以及国内ESG概念被广泛认可，国内多家商业银行发布了低碳及ESG相关主题的理财产品等银行零售产品。
        对于这些银行零售产品，受限于数据披露水平，我们无法进行系统的“气候友好型”产品筛选，但考虑到这一品类是个人投资者参与气候投融资的重要渠道，
        且市场创新发展迅速，我们整理了一些市场上已有的绿色概念零售银行产品案例，供读者参考。
        `,
    },
    financeHead:
    {
        tableTh1: '产品名称',
        tableTh2: '产品定义:',
        tableTh3: '实践银行及相关产品',
    },
    financeTable: [{
        id: 1,
        prodName: '可持续存款',
        prodDefine: '存入绿色存款的资金将被用于支持绿色和可持续相关项目',
        bankAndRelateProd: `
        <p><a href="https://www.sc.com/cn/deposits/sucd/" target="_blank">渣打银行——个人可持续发展大额存单</a></p>
        <p><a href="https://www.business.hsbc.com.cn/zh-cn/campaigns/sustainable-finance/green-deposits" target="_blank">汇丰银行——企业绿色存款计划</a></p>
        <p>中国银行（上海分行）——企业绿色存款</p>`,
        bankList: [{
            name: '渣打银行',
            link: `<p><a href="https://www.sc.com/cn/deposits/sucd/" target="_blank">个人可持续发展大额存单>></a></p>`,
        }, {
            name: '汇丰银行',
            link: `<p><a href="https://www.business.hsbc.com.cn/zh-cn/campaigns/sustainable-finance/green-deposits" target="_blank">企业绿色存款计划>></a></p>`,
        }, {
            name: '中国银行（上海分行）',
            link: `<p><a href="https://mp.weixin.qq.com/s/JimT0nbmjs3vZuXDGfUxqw" target="_blank">企业绿色存款>></a></p>`,

        }]
    }, {
        id: 2,
        prodName: '绿色汽车消费贷款',
        prodDefine: '绿色汽车消费贷指商业银行面向个人、家庭和小微企业购买节能型与新能源汽车等绿色交通工具提供的消费贷款',
        bankAndRelateProd: `
        <a href="https://creditcard.ecitic.com/h5/shenqing/iche/index.html?cls=SJWAPICBCB02&sid=SJWAPPT" target="_blank">
        中信银行——中信银行i车信用卡、特斯拉车主汽车消费贷款业务</a>`,
        bankList: [{
            name: '中信银行',
            link: `<p><a href="https://creditcard.ecitic.com/h5/shenqing/iche/index.html?cls=SJWAPICBCB02&sid=SJWAPPT" target="_blank">中信银行i车信用卡、特斯拉车主汽车消费贷款业务>></a></p>`,
        }]
    }, {
        id: 3,
        prodName: '绿色建筑按揭贷款',
        prodDefine: '银行面向房地产企业开发的绿色建筑、被动式建筑和装配式建筑属于绿色开发贷范围，针对个人和住户以及企业购买绿色建筑、被动式建筑和装配式建筑向银行申请的融资，可以归为绿色建筑消费信贷或绿色建筑按揭贷款',
        bankAndRelateProd: `
        <p>兴业银行——绿色按揭贷</p>
        <p><a href="http://www.masrcb.com/info/1008/2459.htm" target="_blank">马鞍山农商行——绿色住房按揭贷</a></p>`,
        bankList: [{
            name: '兴业银行',
            link: `<p><a href="https://www.cib.com.cn/cn/aboutCIB/about/news/2018/20180412_2.html" target="_blank">绿色按揭贷>></a></p>`,
        }, {
            name: '马鞍山农商行',
            link: `<p><a href="http://www.masrcb.com/info/1008/2459.htm" target="_blank">绿色住房按揭贷>></a></p>`,
        }]
    }, {
        id: 3,
        prodName: '绿色普惠农林贷款',
        prodDefine: '商业银行面向农户和林户提供的贷款。在国内绿色 消费贷中主要表现为林权抵押贷款以及生态农业贷款等。林权抵押贷款是以森林、林木所有权和林地使用权抵押作为保证方式 所取得的贷款，以满足农民等主体的林业生产经营及其他生产、生活 相关的资金需求',
        bankAndRelateProd: `<p>兴业银行——林权按揭贷款</p>`,
        bankList: [{
            name: '兴业银行',
            link: `<p><a href="http://city.ce.cn/news/202112/22/t20211222_7334217.shtml" target="_blank">林权按揭贷款>></a></p>`,

        }]
    }, {
        id: 5,
        prodName: '绿色信用卡',
        prodDefine: '商业银行为提倡“低碳”生活、鼓励消费者加入绿色环保行列而推出的绿色环保信用卡，提倡绿色消费。绿色行动方式可以分为：植树造林、购买碳减排量、环境维权等',
        bankAndRelateProd: `
        <p>光大银行-绿色零碳信用卡</p>
        <p>中国农业银行-金穗环保卡</p>`,
        bankList: [{
            name: '光大银行',
            link: `<p><a href="https://cbeex.com.cn/article/ywzx/tjyzx/txyk/" target="_blank">绿色零碳信用卡>></a></p>`,
        }, {
            name: '中国农业银行',
            link: `<p><a href="http://www.acef.com.cn/a/gyb/gyxmtj/2013/1214/238.html" target="_blank">金穗环保卡>></a></p>`,

        }]
    }, {
        id: 6,
        prodName: '绿色光伏贷',
        prodDefine: '绿色光伏贷是支持家庭光伏发电专门定制的贷款产品，主要用于支付光伏电站成套设备费用的消费类贷款，该贷款以家庭收入、国家补贴及余电上网收益为主要还款来源',
        bankAndRelateProd:
            `
        <p><a href="http://www.jsbchina.cn/CN/gsyw/cgsyw/gxmrzl/541.html" target="_blank">江苏银行——光伏贷</a></p>
        `,
        bankList: [
            {
                name: '江苏银行',
                link: `<p><a href="http://www.jsbchina.cn/CN/gsyw/cgsyw/gxmrzl/541.html" target="_blank">光伏贷>></a></p>`,
            }
        ]
    }, {
        id: 7,
        prodName: '个人碳账户',
        prodDefine: '金融机构通过金融服务追踪用户碳减排行为，对用户碳减排行为进行量化，形成碳账户余额，继而将碳账户余额和金融服务、用户权益挂钩，未来甚至可以将碳账户余额折算成真正的现金',
        bankAndRelateProd: `
        <p><a href="http://www1.ccb.com/cn/v3/include/notice/upload/20220829_1661767732/20220829182230773852.pdf" target="_blank">建设银行——碳账本</a></p>
        <p>中信银行——中信碳账户</p>
        <p>平安银行——个人碳账户</p>
        <p>衢江农商行——个人碳账户</p>`,
        bankList: [{
            name: '建设银行',
            link: `<p><a href="http://www1.ccb.com/cn/v3/include/notice/upload/20220829_1661767732/20220829182230773852.pdf" target="_blank">碳账本>></a></p>`,
        }, {
            name: '中信银行',
            link: `<p><a href="https://mp.weixin.qq.com/s/C1YBiVYvM1HPzmXb6h80sg" target="_blank">中信碳账户>></a></p>`,

        }, {
            name: '平安银行',
            link: `<p><a href="https://mp.weixin.qq.com/s/GyUL2Fa1Vmn7qWuGVmDMbg" target="_blank">个人碳账户>></a></p>`,

        }, {
            name: '衢江农商行',
            link: `<p><a href="https://zj.zjol.com.cn/news.html?id=1718449" target="_blank">个人碳账户>></a></p>`,

        }]
    },
    ],

    // 基金/债券
    financeTab: {
        tab1: '基金',
        tab2: '债券',
        tab3: '银行零售产品'
    },
    financeFilter: {
        title: '过滤器:',
        methodBtn: '方法学说明',
        clearBtn: '清除所有',
        funsType: '基金类别',
        manage: '基金管理人',
        standard: '选择标准',
        selfSearch: '自主筛选',
        okBtn: '确定',
        standardDiff: '了解2项标准差异',
        fundDiffBtn: '了解3种类别基金的区别',
        bondDiffBtn: '了解5种类别债券的区别',
        issuerObjBtn: '了解3种发行对象的区别',
        issuer: '发行对象',
        bondType: '债券类型',
        placeholder: '请选择基金经理人',
        diyFundsTip1: '气候解决方案公司公允价值比例高于',
        diyFundsTip2: '可填50%-100%',
        diyFundsTip3: '化石燃料相关持仓公允价值比例低于',
        diyFundsTip4: '可填0-5%',
        diyBondTip1: '气候友好资金用途占比比例高于',
        diyBondTip2: '可填50%-100%',
    },
    financeFundsTable: {
        tableTh1: '序号',
        tableTh2: '代码',
        tableTh3: '基金管理人',
        tableTh4: '基金',
        tableTh5: `<div>是否为气候友好型基金</div><span>（FFF标准）</span>`,
        tableTh6: '基金类别',
        tableTh7: '官网链接',
        tableTh8: '气候解决方案公司公允价值比例',
        tableTh9: `<div>化石燃料相关持仓公允价值比例</div><span>（参考标准2）</span>`,
        tableTh10: `<div>化石燃料相关持仓公允价值比例</div><span>（参考标准1）</span>`,
    },
    financeBondsTable: {
        tableTh1: '序号',
        tableTh2: '债券代码',
        tableTh3: '债券简称',
        tableTh9: '债券类型',
        tableTh4: '发行对象',
        tableTh5: `<div>是否为气候友好型债券</div><span>（SFDR）</span>`,
        tableTh6: '气候友好资金用途占比',
        tableTh7: `<div>化石燃料相关持仓公允价值比例</div><span>（参考标准2）</span>`,
        tableTh8: `<div>化石燃料相关持仓公允价值比例</div><span>（参考标准1）</span>`,
    },
    financeFooter: {
        tip: '如您对我们的气候友好投资产品项目和成果感兴趣，或有任何建议和意见，欢迎与我们联系！',
        email: '联系邮箱',
        wechat: '微信联系',
        deadLine: ` | <span>更新截止日期:</span>`,

    },

    // 基金类别
    financeFundsType: [
        { name: "指数型", code: 1 },
        { name: "股票型", code: 2 },
        { name: "混合型", code: 3 },
    ],
    // 选择标准
    financeSelectStandard: [
        { name: "标准1（严格）", code: 1 },
        { name: "标准2", code: 2 },

    ],
    //发行对象
    financeIssueObject: [
        { name: "全国银行间债券市场成员", code: 1 },
        { name: "合格机构投资者", code: 2 },
        { name: "境内法人和自然人", code: 3 },
    ],
    // 错误消息
    financeError: {
        error1: '请输入合法数字',
        error2: '请填50-100之间的数值',
        error3: '请填0-5之间的值',
    },
    //债券类别
    financeBondType: [
        { name: "企业债券", code: 1 },
        { name: "公司债券", code: 2 },
        { name: "短期融资券", code: 3 },
        { name: "中期票据", code: 4 },
        { name: "政府支持债券", code: 5 }
    ],
}
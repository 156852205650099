<template>
  <!-- 首页-底部布局 -->
  <footer class="app-footer">
    <div class="middle-box">
      <!-- 左侧logo -->
      <div class="logo">
        <router-link to="/">
          <svg-icon icon-class="logo" />
        </router-link>
      </div>
      <div class="container">
        <div class="qr-code">
          <div>
            <svg-icon icon-class="public-account" />
          </div>
          <p>{{ $t("homeFooter.publicAccount") }}</p>
        </div>
        <div class="nav">
          <ul>
            <li>
              <router-link to="/about">{{
                $t("homeFooter.aboutPro")
              }}</router-link>
            </li>
            <li>
              <router-link to="/contact">{{
                $t("homeFooter.contact")
              }}</router-link>
            </li>
            <li>
              <a href="http://www.governance-solutions.com" target="_blank">{{
                $t("homeFooter.link")
              }}</a>
            </li>
            <li>
              <p @click="handleclick((index = 1))">
                {{ $t("homeFooter.suggest") }}
              </p>
            </li>
            <li>
              <router-link to="/legal">{{
                $t("homeFooter.legal")
              }}</router-link>
            </li>
          </ul>
        </div>
        <div class="contact">
          <FooterTab :tabList="tabList" :tabContents="tabContents" />
        </div>
      </div>
    </div>

    <!-- 版权信息 -->
    <div class="copyright">
      <p>CopyRight © 鼎力可持续数字科技（深圳）有限公司</p>
    </div>
    <Connect :isShow="showConnect" @close="close" />
  </footer>
</template>

<script>
import Connect from "@/components/ConnectDia";
import FooterTab from "./Tab.vue";
export default {
  name: "AppFooter",
  components: {
    FooterTab,
    Connect,
  },
  data() {
    return {
      showConnect: false, //联系邮箱
      tabList: [],
      tabContents: [],
    };
  },

  computed: {
    language() {
      return this.$store.getters.language;
    },
  },

  // 监听语言
  watch: {
    language: {
      handler(newV, oldV) {
        if (oldV !== newV) {
          this.getList();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.getList();
  },
  methods: {
    // 获取list
    getList() {
      this.tabList = this.$t("homeTabList");
      this.tabContents = this.$t("homeTabContents");
    },
    handleclick(i) {
      this.showConnect = false;

      if (i == 1) {
        this.showConnect = true; //点击联系邮箱
      }
    },
    close() {
      this.showConnect = false;
    },
  },
};
</script>

<style scoped lang='scss'>
.app-footer {
  position: relative;
  height: 145px;
  width: 100%;
  padding: 20px 60px;
  overflow: hidden;
  background: #112445;
  font-size: 12px;
  a {
    color: #fff;
  }
  .middle-box {
    display: flex;
    justify-content: space-between;
    .logo {
      a {
        display: flex;
        align-items: center;
        margin-right: 20px;
        height: 70px;
        line-height: 70px;
        width: 100%;
        .svg-icon {
          height: 40px;
          width: 160px;
        }
      }
    }
  }

  // 版权
  .copyright {
    color: #999;
    // text-align: center;
    line-height: 40px;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
  }

  //内容部分
  .container {
    width: 1200px;
    display: flex;
    text-align: center;
    justify-content: space-around;
    color: #fff;
    .nav,
    .contact {
      margin-left: 40px;
    }
    .contact {
      min-width: 550px;
    }
    .qr-code {
      .svg-icon {
        width: 60px;
        height: 60px;
      }
      > p {
        min-width: 170px;
      }
    }
    .nav {
      min-width: 450px;
      ul {
        display: flex;
        li {
          min-width: 75px;
          margin: 0 10px;
        }
      }
    }
    .concat {
      :deep(.el-tabs--border-card) {
        background: unset !important;
        border: unset;
      }
    }
  }
}

// 小于798
@media (max-width: 769px) {
  .logo {
    min-width: 100px;
    a {
      background-size: 100% 80%;
    }
  }
}
</style>
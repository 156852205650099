import request from '@/utils/request'
/**
* 0.查询首页信息
* @param  
* @param 
* @return Promise
* */
export function getInfor(query) {
  return request({
    url: '/unAuth/security/info',
    method: 'get',
    params: query
  })
}
/**
* 1.获取基金列表
* @param  
* @param 
* @return Promise
* */
export function getFundsList(data) {
  return request({
    url: '/unAuth/security/funds',
    method: 'post',
    data
  })
}

/**
* 2.获取基金经理人
* @param  
* @param 
* @return Promise
* */
export function getManagerList() {
  return request({
    url: '/unAuth/security/managers',
    method: 'get',
  })
}

/**
* 3.获取债券列表
* @param  
* @param 
* @return Promise
* */
export function getBondsList(data) {
  return request({
    url: '/unAuth/security/bonds',
    method: 'post',
    data
  })
}

/**
* 4.发送邮件
* @param  
* @param 
* @return Promise
* */
export function sendEmail(data) {
  return request({
    url: '/unAuth/security/sendMail',
    method: 'post',
    data
  })
}

/**
* 5.下载基金报告
* @param  
* @param 
* @return Promise
* */
export function downloadFunds(data) {
  return request({
    url: '/unAuth/security/downloadFunds',
    method: 'post',
    responseType: 'blob',
    data
  })
}

/**
* 6.下载债券报告
* @param  
* @param 
* @return Promise
* */
export function downloadBonds(data) {
  return request({
    url: '/unAuth/security/downloadBonds',
    method: 'post',
    responseType: 'blob',
    data
  })
}





<template>
  <!-- 联系邮箱 -->
  <el-dialog
    title=""
    :visible.sync="isShow"
    class="dia-box"
    :before-close="cancel"
    center
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      v-loading="loading"
      :element-loading-text="$t('connectDia.sendingMsg')"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255,255,255,.6)"
    >
      <el-form-item
        :label="$t('connectDia.name')"
        :label-width="formLabelWidth"
        prop="username"
      >
        <el-input
          v-model="form.username"
          autocomplete="off"
          :placeholder="$t('connectDia.placeholderName')"
        ></el-input>
      </el-form-item>

      <el-form-item
        :label="$t('connectDia.role')"
        :label-width="formLabelWidth"
        prop="role"
      >
        <el-input
          v-model="form.role"
          autocomplete="off"
          :placeholder="$t('connectDia.placeholderRole')"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('connectDia.company')"
        :label-width="formLabelWidth"
        prop="company"
      >
        <el-input
          v-model="form.company"
          autocomplete="off"
          :placeholder="$t('connectDia.placeholderCompany')"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('connectDia.phone')"
        :label-width="formLabelWidth"
      >
        <el-input
          v-model="form.phone"
          autocomplete="off"
          :placeholder="$t('connectDia.placeholderPhone')"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('connectDia.email')"
        :label-width="formLabelWidth"
        prop="email"
      >
        <el-input
          v-model="form.email"
          autocomplete="off"
          :placeholder="$t('connectDia.placeholderEmail')"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('connectDia.emailSubject')"
        :label-width="formLabelWidth"
        prop="subject"
      >
        <el-input
          v-model="form.subject"
          autocomplete="off"
          :placeholder="$t('connectDia.placeholderSubject')"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('connectDia.content')"
        :label-width="formLabelWidth"
        prop="context"
      >
        <el-input
          v-model="form.context"
          type="textarea"
          autocomplete="off"
          :placeholder="$t('connectDia.placeholderContent')"
          cols="20"
          rows="4"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancel" size="small">{{
        $t("connectDia.cancel")
      }}</el-button>
      <el-button type="primary" @click="submit" size="small">{{
        $t("connectDia.submit")
      }}</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { sendEmail } from "@/api/nex";
export default {
  props: ["isShow"],
  data() {
    return {
      loading: false,
      form: {
        username: undefined,
        company: undefined,
        role: undefined,
        email: undefined,
        subject: undefined,
        phone: undefined,
        context: undefined,
      },
      // 表单校验
      rules: {
        username: [
          {
            required: true,
            message: this.$t("connectDia.errorMsg1"),
            trigger: "blur",
          },
        ],
        company: [
          {
            required: true,
            message: this.$t("connectDia.errorMsg1"),
            trigger: "blur",
          },
        ],
        role: [
          {
            required: true,
            message: this.$t("connectDia.errorMsg1"),
            trigger: "blur",
          },
        ],

        email: [
          {
            required: true,
            message: this.$t("connectDia.errorMsg1"),
            trigger: "blur",
          },
          {
            type: "email",
            message: this.$t("connectDia.errorMsg2"),
            trigger: ["blur", "change"],
          },
        ],

        subject: [
          {
            required: true,
            message: this.$t("connectDia.errorMsg1"),
            trigger: "blur",
          },
        ],
        context: [
          {
            required: true,
            message: this.$t("connectDia.errorMsg1"),
            trigger: "blur",
          },
        ],
      },
      formLabelWidth: "145px",
    };
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
  },
  watch: {
    language: {
      handler(newV, oldV) {
        if (oldV !== newV) {
          this.formLabelWidth =
            this.$store.getters.language === "en_us" ? "145px" : "100px";
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    // 表单重置
    reset() {
      this.form = {
        username: undefined,
        company: undefined,
        role: undefined,
        email: undefined,
        subject: undefined,
        phone: undefined,
        context: undefined,
      };
      this.resetForm("form");
    },
    //发送邮件
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true; //局部遮罩
          sendEmail(this.form)
            .then((response) => {
              this.cancel(); //关闭弹框
              this.loading = false; //局部遮罩
              this.$message({
                message: this.$t("connectDia.successMsg1"),
                type: "success",
              });
            })
            .catch((err) => {
              this.loading = false; //局部遮罩
              console.log(err);
            });
        }
      });
    },
    // 取消按钮
    cancel() {
      this.reset();
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.dia-box {
  :deep(.el-dialog) {
    width: 400px;
  }
  :deep(.el-form-item__label) {
    text-align: justify;
    // text-align-last: justify;
    font-weight: 700;
  }
  :deep(.el-input--medium) {
    font-size: 13px;
  }
  :deep(.el-dialog--center .el-dialog__body) {
    padding: 15px 25px 0px;
  }
  :deep(.el-form-item) {
    margin-bottom: 21px;
  }
  :deep(.el-dialog__header) {
    padding: 15px 20px 10px;
  }
  :deep(.el-textarea__inner) {
    word-break: break-word;
  }
}
</style>

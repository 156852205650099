<template>
  <header class="app-header" ref="header">
    <div class="navbar">
      <!-- 左侧logo -->
      <div class="logo">
        <router-link to="/">
          <svg-icon icon-class="logo" />
        </router-link>
      </div>

      <div class="container">
        <!-- 导航栏 -->
        <TopNav d="topmenu-container" class="topmenu-container"/>
      </div>

      <!-- 中英文切换 -->
      <div class="language_box">
        <!-- 地球图标 -->
        <div class="el-icon-dl">
          <svg-icon slot="prefix" icon-class="earth" />
        </div>
        <lang-select class="set-language" />
      </div>
    </div>
  </header>
</template>
<script>
import TopNav from "@/components/TopNav/index.vue";
export default {
  name: "AppTopnav",
  components: { TopNav },
  data() {
    return {
      headerFixed: undefined,
    };
  },
  mounted() {
    this.$nextTick(function () {
      const header = this.$refs.header; //获取吸顶元素的dom
      this.offsetTop = header.offsetTop; //吸顶元素到top的距离
      this.offsetHeight = header.offsetHeight; //元素自身高度
      window.addEventListener("scroll", this.handleScroll); //监听滚动条
    });
  },
  methods: {
    handleScroll() {
      // 得到页面滚动的距离
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 判断页面滚动的距离是否大于吸顶元素的位置
      this.headerFixed = scrollTop > this.offsetTop - this.offsetHeight * 2;
    },
  },
  destroyed() {
    //销毁
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style scoped lang="scss">
.app-header {
  width: 100%;
  position: fixed;
  top: 0px;
  z-index: 2001;
  .navbar {
    background: #112445;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.05);
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 60px;
    overflow: hidden;
    width: 100%;
  }
  .logo {
    a {
      display: flex;
      align-items: center;
      margin-right: 20px;
      height: 70px;
      line-height: 70px;
      width: 100%;
      .svg-icon {
        height: 40px;
        width: 160px;
      }
    }
  }
  // 多语言
  .language_box {
    display: flex;
    align-items: center;
    margin-left: 10px;
    .svg-icon{
      width: 1.5em;
      height: 1.5em;
      vertical-align: middle;
    }
    .set-language {
      margin-left: 5px;
      display: inline;
    }
  }
}
// 小于798
@media (max-width: 769px) {
  .navbar {
    .logo {
      min-width: 100px;
      a {
        background-size: 100% 80%;
      }
    }
  }
}
</style>
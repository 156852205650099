
import cache from './cache'

// import download from './download'

export default {
  install(Vue) {

    // 缓存对象
    Vue.prototype.$cache = cache

    // 下载文件
    // Vue.prototype.$download = download
  }
}

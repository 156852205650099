import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
/* Layout */
import Layout from '@/layout'

// 公共路由
export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect')
      }
    ]
  },
  {
    path: '/404',
    component: () => import('@/views/error/404'),
    hidden: true
  },
  {
    path: '',
    component: Layout,
    redirect: 'home',
    children: [
      {
        path: '/home',//首页
        component: () => import('@/views/home.vue'),
        name: 'Home',
        meta: { title: 'Home' }
      }]
  },
  {
    path: '/more',//方法学说明  
    component: Layout,
    children: [
      {
        path: '',
        component: () => import('@/views/method-desc/index.vue'),
        name: 'Methods',
        meta: { title: 'Methods' }
      },
      {
        path: 'appendix',//附录  
        component: () => import('@/views/method-desc/appendix.vue'),
        name: 'Appendix',
        meta: { title: 'Appendix', activeMenu: '/more' }
      },
    ]
  },
  {
    path: '/frinedly',//气候友好产品库
    component: Layout,
    children: [
      {
        path: '',
        component: () => import('@/views/friendly-products/index.vue'),
        name: 'Library',
        meta: { title: 'Library', activeMenu: '/frinedly' }
      },
    ]
  },
  {
    path: '/QA',//FAQ
    component: Layout,
    children: [{
      path: '',
      component: () => import('@/views/FAQ/index.vue'),
      name: 'FAQ',
      meta: { title: 'QA' }
    }]

  },
  {
    path: '/about',
    component: Layout,
    meta: { title: 'About' },
    children: [
      {
        path: '',//关于项目
        component: () => import('@/views/aboutus/aboutProject.vue'),
        name: 'project',
        meta: { title: 'project', activeMenu: '/about' }
      },
      // {
      //   path: 'aboutTeam',
      //   component: () => import('@/views/aboutus/aboutTeam.vue'),
      //   name: 'team',
      //   meta: { title: 'team', activeMenu: '/about' }
      // }
    ]
  },
  {
    path: '/contact',//联系我们
    component: Layout,
    children: [{
      path: '',
      // component: () => import('@/views/contactus/index.vue'),
      component: () => import('@/views/contactus/index_v1.0.vue'),
      name: 'Contact',
      meta: { title: 'Contact' }
    }]
  },
  {
    path: '/legal',//气候友好产品库
    component: Layout,
    children: [
      {
        path: '',
        component: () => import('@/views/legal/index.vue'),
        name: 'Legal',

      },
    ]
  },
]

// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history', // 去掉url中的#，部署的时候要注释掉这行代码
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})
export default router

import Vue from 'vue'
import App from './App.vue'
import Cookies from 'js-cookie'
import router from './router'
import store from './store'
import Element from 'element-ui'
import plugins from './plugins' // plugins
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/style/index.scss' // global css
import './assets/icons' // icon
import '@/filters/filter.js'// 注册全局过滤器
import './mock'// 引入mock

import './assets/font/font.css'//外部字体
import i18n from './lang'//国际化
import Pagination from "@/components/Pagination";// 分页组件
import LangSelect from "@/components/LangSelect";// 多语言
import { resetForm,formatDate } from "@/utils/nex";
import DictTag from '@/components/DictTagDL'// 字典标签组件
import SideCatalog from '@/components/Article';//目录
Vue.config.productionTip = false

// 全局方法挂载
Vue.prototype.formatDate = formatDate
Vue.prototype.resetForm = resetForm

// 全局组件挂载
Vue.component('Pagination', Pagination)
Vue.component('LangSelect', LangSelect)
Vue.component('DictTag', DictTag)
Vue.component('SideCatalog', SideCatalog);
Vue.use(plugins)
Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value),
  size: Cookies.get('size') || 'medium', // set element-ui default size
})

window.vm = new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
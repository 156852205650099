//格式化数据，保留两位小数
export function KeepTwo(value) {
    //如果是数字，就保留2位小数；如果非数字，就直接返回原值；如果似是空值，就返回”“
    if (value === '' || value == "null" || value == "undefined") {
        return "";
    } else if (isNumber(value)) {//非负浮点数
        return Number(value).toFixed(2)
    } else {
        return value
    }
}

export function ChangeToPercent(value) {
    //如果是数字，就转换为百分数，并保留3位小数；如果非数字，就直接返回原值；如果似是空值，就返回”“
    if (value === '' || value == "null" || value == "undefined") {
        return "";
    } else if (isAllTypeNumber(value)) {//是数字
        let percent = Number(value * 100).toFixed(3)
        percent += '%'
        return percent
    } else {
        return value
    }
}
function isNumber(val) {
    let regPos = /^\d+(\.\d+)?$/;//非负浮点数
    if (regPos.test(val)) {
        return true
    } else {
        return false
    }
}
function isAllTypeNumber(val) {
    let regPos = /(^[\-0-9][0-9]*(\.[0-9]+)?)$/;//正负负浮点数
    if (regPos.test(val)) {
        return true
    } else {
        return false
    }
}

// 把小写字母转为大写字母
export function changeToUpperCase(str) {
    return str.toUpperCase()
    
}
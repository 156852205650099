// 法律声明
export default {
    legalTop: {
        title: '法律声明'
    },

    // 使用条款
    legalSection1: {
        title: "使用条款",
        content1: `本使用条款适用于气候友好型投资产品网站（https://www.climatefriendlyinvest.com/）。您使用本网站，即表示您接受并同意本使用条款。在法律允许范围内，
        本公司保留自行决定任何时候变更本使用条款内容的权利，您有责任定期查看本使用条款的变更。您在变更信息发布之后继续使用本网站，即表示您同意且接受该变更。`,

        title2: "商标和版权",
        content2: `
        <p>本网站包含的所有文字、图片、音乐、视频、动画、商标、图案、图表、可视界面及计算机编码（“内容”），包括但不限于内容的创意、设计、结构、表现方式、呈现结果等，
        均为本公司所有、受本公司控制或者由本公司获得合法授权而使用。除非本使用条款明确规定，未经本公司事先书面同意，您不得为任何商业目的以任何方式使用本网站的内容。
        任何被授权的浏览、复制、打印和传播本网站内的资料内容都必须符合以下条件：</p>
        <p>• 仅为个人学习、研究使用，不得用于商业目的；</p>
        <p>• 不对该资料内容做任何修改；</p>
        <p>• 在复制使用任何部分或完整的内容时，必须包括本公司的版权声明或包括原始内容中包含的其他版权声明。</p>
        <p>本网站所提供的内容、或由于您使用本网站而提供给您的内容，其知识产权属本公司或其他相关权利人所有。在本网站上使用和出现的商标、商标名、
        以及标志（统称为商标），为本公司和其他相关权利人所拥有的注册和非注册商标。</p>
        <p>本网站上的任何事物都不应被用户视为得到许可或有权使用本网站上的任何商标。未经本公司或其他相关权利人的许可，
        用户不得擅自使用与这些商标相同的商标。</p>
        `,

        title3: "使用规范",
        content3: `
        您不可为任何非法或本使用条款禁止的目的使用本网站，不得利用本网站及其内容侵犯其他组织或个人的合法权益。您不可使用包括spider，robot，deep-link, 
        page-scrape在内的，任何形式的装置、程序、算法或者任何相同或相似的手动程序，访问、获取、复制或监测本网站任何部分或任何内容，亦不可通过任何非本网站提供的方式访
        问、获取、复制本网站的任何资料、文件或信息。您不可试图通过非法侵入、破解密码或任何其他非法方式，未经授权访问本网站的任何内容或使用本网站的任何服务。
        你不可追踪、反向查找、破解、破译本网站的任何客户的信息，包括但不限于任何非您所有的账户。您不可违反本网站或链接至本网站的任何网络安全机制或认证措施，不可探测、
        扫描或者测试本网站或连接至本网站的任何网络弱点，您不可向本网站发起任何形式的网络攻击。您同意不使用任何设备、软件或程序，干扰或试图干扰本网站的正常运行或在
        本网站进行的任何交易，或干扰或试图干扰他人使用本网站。你不得采取任何行为，在本网站基础架构、系统或网络上，或链接至本网站的系统或网络上，存储不合理或不合比例的
        的大量数据。
        `,

        title4: "无担保声明",
        content4: `本公司就本网站各项服务的安全、无误及不中断不承担任何明示或默示的保证责任。
        您须自行承担为数据和/或设备作充分保护和备份，并采取合理而适当的预防措施以
        扫描电脑病毒或其他毁灭性产物的责任。本公司在编制本网站所载信息和资料时已力求审慎。
        但本公司只能按目前现状提供有关信息，并不作任何明示或暗示的保证。尤其是，
        不提供与这些信息内容相关的无违反安全性、准确性、某种特殊用途的适用性、以及免于计算机病毒侵害的担保。
        `,

        title5: "外部链接",
        content5: `
        <p>本网站中的某些链接可能会转向其他独立第三方网站，该类链接仅为方便本网站的使用者而提供，本公司不对链接网站的内容、产品、服务、
        信息等提供任何明示和暗示的保证，此类链接也不视为本公司对链接网站的推荐或授权。您需自行独立判断您与链接网站的互动行为，本公司对此
        不承担任何责任。
        </p>
        `,

        title6: "权利保留",
        content6: `<p>本公司保留在任何时候不经通知进行以下行为的权利：</p>
        <p>• 基于任何原因，暂停或终止本网站全部或部分的运行和访问；</p>
        <p>• 修改或变更本网站的全部或部分内容及其适用的政策或条款；</p>
        <p>• 在进行定期或非定期维护、错误纠正或其他变更所必须时，中断本网站全部或部分的运行和访问。</p>
        `,

        title7: "其他",
        content7: `
        <p>本使用条款构成您与本公司之间与您使用本网站相关的整体协议，并在此取代您与本公司之间先前存在的与使用本网站的任何及所有书面或口头协议。
        除在您与本公司签订的其他协议中规定的情形外，本公司不接受任何对本使用条款的反要约，且在此明确拒绝所有反要约。</p>
        <p> 若本公司未坚持或强制要求严格履行本使用条款，不应被视为本公司对本使用条款任何内容或执行本使用条款的任何权利的放弃，
        本公司与您或任何其他第三方之间的任何行为不应被视为对本使用条款任何规定的修改。<p>
        <p>上述任何条款因任何原因被确认无法强制执行的，都不影响其他条款的效力，其他条款仍继续适用。</p>
        <p>进入、访问与使用本网站，以及本条款与条件受中华人民共和国法律管辖，并依照中华人民共和国法律解释。若因本网站产生的任何争议，
        将以本公司所在地法院为管辖法院，除非中国法律对此有强制性规定。</p>
        `,
    },

    // 免责声明
    legalSection2: {
        title: "免责声明",
        content1: `<p>请仔细阅读以下条款与条件。如果您进入、访问或使用本网站及其任何网页，即表明您已同意接受以下条款与条件的约束。
        下列条款与条件可能会作出变更。如您不同意下列条款与条件，请勿进入、访问或使用本网站或其任何信息。</p>
        <p>“本网站”指由鼎力可持续数字科技（深圳）有限公司（以下简称“本公司”）所有并发布的本气候友好型投资产品网站（https://www.climatefriendlyinvest.com/）及其网页内包含的所有信息及材料。
        </p>
         <p>1. 本网站所载信息和资料皆由本公司结合相关产品的公开信息及本公司与世界领先的新能源科创服务企业New Energy Nexus共同研究确定的方法论分析得出，
         本公司尽力严谨处理本网站所载信息和资料，但本公司对其准确性、足够性、完整性及其使用的适当性等不作任何担保。</p>
         <p>2. 本网站介绍的信息、工具和资料仅供一般性参考，除非另有明确协议，不应被视为购买或销售任何金融产品的某种要约，亦非对任何交易的正式确认。
         本网站介绍的信息、工具和资料并非旨在提供任何形式的建议，包括但不限于投资、税收、会计或法律上的建议。
        投资有风险，基金的过往业绩并不预示其未来表现，您在作出投资决策前应认真阅读产品招募说明书及其在指定信息披露媒体上发布的正式公告和有关信息，并自行承担投资风险。</p>
        
        <p>3. 本公司就本网站各项服务的安全、无误及不中断不承担任何明示或默示的保证责任。您同意自行承担使用本网站服务的所有风险及因该等风险可能造成的任何损害。
         对于因技术故障（如传输错误、技术缺陷、第三者侵入等）而导致的任何数据缺失等，本公司不承担任何责任。</p>
         <p>4. 本网站的内容将定期更新或修正。本公司对网站所载或所涉及的信息和资料的及时性不作任何担保或保证。本公司保留无需事先通知即可删除或修改网站信息的权利。
        除非存在故意不当或欺诈行为，本公司及其分支机构、关联企业、董事、员工对于因您进入、访问或使用本网站，
        或无法进入、访问或使用本网站而可能对您导致的任何直接或间接损失，不承担任何责任。</p>
        <p>5. 除非存在故意不当或欺诈行为，本公司及其分支机构、关联企业、董事、员工对于因您进入、访问或使用本网站，或无法进入、访问或使用本网站而可能对您导致的任何直接或间接损失，不承担任何责任。</p>
        `,

    }
}
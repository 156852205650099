// FAQ
export default {
    faqTop: {
        title: '常见问题Q&A'
    },
    faqSection1: {
        title: "(一)气候友好分析方法",
        title1: `1.什么是气候解决方案公司？`,
        content1: `公司主营业务符合我们认可的55项气候友好经济活动中的至少一项，且气候友好业务对应的营业收入占比高于50%的公司被定义为“气候解决方案公司“。`,
        title2: "2.气候解决方案公司是否有关于化石燃料相关业务的限制规定？",
        content2: `气候解决方案公司不对化石燃料相关业务作出限制，但是在判断公司 / 债券是否涉及化石燃料过程中会依据SFDR和Fossil Free Funds对化石燃料相关业务作出限制。`,
        title3: "3.气候友好行业占营业总收入的比例是如何划分的？",
        content3: `先对上市公司营业收入拆分为分行业收入，再依据drawdown list判断各行业是否与气候解决方案相关，求和气候解决方案对应收入占比得到气候友好行业收入占比。`,
        title4: "4.为什么气候友好型债券规定不允许有化石燃料相关资金投入，而气候友好型基金则设置5 % 的化石燃料资金阈值？",
        content4: `因为气候友好型债券募集资金用途明确且稳定，因此规定不允许有化石燃料资金投入，而基金投资标的较多，并且具有波动性，因此我们允许一定的化石燃料阈值容错去抵消这部分不确定性。`,
    },
    faqSection2: {
        title: "(二)投资产品其他信息",
        title1: "5.基金产品的三种类型是什么？",
        content1: `<div>1) 股票型基金</div>
                   <p>采取主动投资策略，并且80%以上的基金资产投资于股票的基金。</p>
                   <div>2) 指数型基金</div>
                   <p>采取被动投资策略跟踪特定指数的基金，包括但不限于ETF。</p>     
                   <div>3) 混合型基金</div>
                   <p>不符合上述“股票型基金”、“指数型基金”定义，并且50%以上的基金资产投资于股票的基金。</p>
                   `,
        title2: "6.债券产品五种类型的含义是什么？",
        content2: `<div>1) 企业债</div>
                   <p>企业债是指境内具有法人资格的企业，依照法定程序发行、约定在一定期限内还本付息的有价证券。企业债一般是由中央政府部门所属机构、国有独资企业或国有控股企业发行，最终由国家发改委核准。</p>
                   <div>2) 公司债</div>
                   <p>公司债券是公司为各种原因（如正在进行的运营、并购或扩大业务）筹集融资而发行的债券,期限至少为一年。</p>
                   <div>3) 短期融资券</div>
                   <p>短期融资券是由企业发行的无担保短期本票，按照《银行间债券市场非金融企业债务融资工具管理办法》的条件和程序在银行间债券市场发行和交易并约定在一定期限内还本付息的油价债券，是企业筹措。</p>
                   <div>4) 中期票据</div>
                   <p>中期票据是指期限通常在5-10年之前的票据，公司发行中期票据通常会透过承办经理安排一种灵活的发行机制，透过单一发行计划，多次发行期限不同的票据。</p>
                   <div>5) 政府支持债券</div>
                   <p>政府支持债券是政府机构债券的一种，我国铁道部发行的中国铁路建设债券被认定为“政府支持债券”。</p> 
                   `,
        title3: "7.债券产品三类发行对象的含义是什么？",
        content3: `
                <div>1) 境内法人与自然人</div>
                <p>境内法人是具有民事权利能力和民事行为能力，依法独立享有民事权利和承担民事义务的组织。包括人的结合团体或依特殊目的所组织的财产。境内自然人是指拥有中华人民共和国国籍的自然人。</p>
                <div>2) 合格机构投资者</div>
                <p>a) 经有关金融监管部门批准设立的金融机构（包括证券公司、基金管理公司及其子公司、期货公司、商业银行、保险公司和信托公司等）</p>
                <p>b) 上述金融机构面向投资者发行的理财产品；</p>
                <p>c) 合格境外机构投资者（QFII），人民币合格境外机构投资者（RQFII）</p>
                <p>d) 净资产不低于人民币1000万元的企事业法人、合伙企业</p>
                <p>e) 名下金融资产不低于人民币300万元的个人投资者</p>
                
                <div>3) 银行间债券市场成员</div>
                <p>a) 在中国境内具有法人资格的商业银行及其授权分支机构；</p>
                <p>b) 在中国境内具有法人资格的非银行金融机构和非金融机构；</p>
                <p>c) 经中国人民银行批准经营人民币业务的外国银行分行；</p>
                `,

        title4: "8.公募基金产品有哪些销售渠道？",
        content4: `
                <p>1) 基金公司直销</p>
                <p>2) 银行代销</p>
                <p>3) 基金销售公司代销</p>
                <p>4) 证券公司代销</p>
                <p>5) 期货公司代销</p>
                <p>6) 保险公司代销</p>
                <p>7) 证券投资咨询公司代销</p>
        `,

        title5: "9.海外投资者有哪些渠道可以购买国内公募基金？",
        content5: `
                <p>1) 互联互通</p>
                <p>2) 债券通</p>
                <p>3) 基金互认（针对零售投资人）</p>
                <p>4) 跨境理财通（针对零售投资人）</p>
               `,

        title6: "10.海外投资者有哪些渠道可以购买国内债券？",
        content6: `<p>1) 结算代理模式：向人民银行申请备案.需注明债券结算代理人；</p>
                    <p>2) 债券通：境内外投资者可通过债券通买卖两个市场的交易流通债券；</p>
                    <p>3) 沪港通：沪港股票市场交易互联互通机制；</p>
                    <p>4) 深港通：深港股票市场交易互联互通机制；
                    <p>5) 沪伦通：上交所与伦敦证券交易所互联互通的机制；</p>`,
    }
}
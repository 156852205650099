// 关于我们

export default {
  aboutDL: {
    title: 'About Governance Solutions Group (GSG)',
    leftTitle: 'Our company',
    section1: `<p>GSG is a one-stop service provider for ESG investment and sustainability with a focus on the Chinese market. 
        It is dedicated to providing ESG data, research and rating services to Chinese and foreign institutional investors for 
        Chinese listed companies, debt issuers, and credit-granting companies; and providing professional services such as carbon 
        management SaaS software platform, carbon neutral solutions, sustainable development strategies, green financing and ESG 
        rating improvement for listed companies, industrial parks, and all companies in the international supply chain.
        `,

    title2: "Global Vision",
    // <p>Global Vision and Perspective</p>
    section2: `
        <p>GSG is a signatory and supporter of several international sustainability-related initiatives.<p>
        `,
    section3List: [{
      name: "United Nations Principles for Responsible Investment (UN PRI)",
      content: `PRI is an international organisation initiated by the United Nations to promote the concept of ESG investment and is committed to achieving an efficient and sustainable global financial system and has become the most influential ESG investment initiative in the world. 
            GSG was one of the first service providers in China to join PRI.`
    }, {
      name: "Global Reporting Initiative (GRI)",
      content: `GRI's Sustainability Reporting Guidelines are the world's most widely used sustainability reporting standard, 
            designed to help companies disclose sustainability topics that impact all their stakeholders.`
    }, {
      name: "Sustainability Accounting Standards Board (SASB)",
      content: `SASB develops ESG KPIs for different industries in a targeted manner, sets sustainability accounting standards, 
            and facilitates global companies to disclose relevant information that has a material impact on financial 
            performance and helps investment decisions.`
    }, {
      name: "Climate Related Financial Disclosure Task Force (TCFD)",
      content: `TCFD was formed by the Financial Stability Board (FSB) to respond to severe gaps in the disclosure of 
            climate-related financial risks. TCFD provides climate change disclosure advice to financial institutions and non-financial corporations in four areas: governance, strategy,
             risk management, and metrics and targets.`
    }, {
      name: "Carbon Disclosure Project (CDP)",
      content: `CDP is currently the most authoritative carbon disclosure platform based on the needs of investors and purchasers. 
            Currently, CDP works with more than 590 global investors with total assets of $110 trillion and hundreds of large multinational corporations to inspire companies 
            to disclose and manage their environmental impact through the power of investors and buyers. As a CDP global silver level strategic partner, 
            GSG is accredited to provide solutions for China's export-oriented companies in the following areas: CDP report preparation, carbon emission measurement, 
            portfolio carbon footprint measurement, SBT (Science Based Target) setting, sustainability strategy development, and TCFD reporting support`
    }],
    section3: `
        <p><strong>• United Nations Principles for Responsible Investment (UN PRI):</strong> PRI is an international organisation initiated by the United Nations to promote the concept of ESG investment and is committed to achieving an efficient and sustainable global financial system and has become the most influential ESG investment initiative in the world. 
        GSG was one of the first service providers in China to join PRI.</p></br></br>

        <p><strong>• Global Reporting Initiative (GRI): </strong>GRI's Sustainability Reporting Guidelines are the world's most widely used sustainability reporting standard, 
        designed to help companies disclose sustainability topics that impact all their stakeholders.</p></br></br>

        <p><strong>• Sustainability Accounting Standards Board (SASB): </strong>SASB develops ESG KPIs for different industries in a targeted manner, sets sustainability accounting standards, 
        and facilitates global companies to disclose relevant information that has a material impact on financial 
        performance and helps investment decisions.</p></br></br>

        <p><strong>• Climate Related Financial Disclosure Task Force (TCFD): </strong>TCFD was formed by the Financial Stability Board (FSB) to respond to severe gaps in the disclosure of 
        climate-related financial risks. TCFD provides climate change disclosure advice to financial institutions and non-financial corporations in four areas: governance, strategy,
         risk management, and metrics and targets.</p></br></br>

        <p><strong>• Carbon Disclosure Project (CDP):</strong>CDP is currently the most authoritative carbon disclosure platform based on the needs of investors and purchasers. 
        Currently, CDP works with more than 590 global investors with total assets of $110 trillion and hundreds of large multinational corporations to inspire companies 
        to disclose and manage their environmental impact through the power of investors and buyers. As a CDP global silver level strategic partner, 
        GSG is accredited to provide solutions for China's export-oriented companies in the following areas: CDP report preparation, carbon emission measurement, 
        portfolio carbon footprint measurement, SBT (Science Based Target) setting, sustainability strategy development, and TCFD reporting support.</p></br></br>
        
        <h3>China Depth</h3>
        </br>
        <p>GSG is one of the first professional service firms in China to focus on ESG and sustainability. The Founder of GSG, Dr. Wang Dequan, is 
        a founding board member of China Finance 40 Forum. He currently serves as an expert of the Green and Sustainable Investment Committee of Asset Management 
        Association of China (AMAC), an expert advisor of the ESG Professional Committee of Insurance Asset Management Association of China, and the deputy 
        director of the Sustainable Investment Committee of the Chinese Asset Management Association of Hong Kong. GSG’s team members come from China Energy 
        Conservation and Environmental Protection Group, SinoCarbon Innovation & Investment, Tencent, Baidu, WeBank, Bank of Huzhou, McKinsey, and PwC. 
        Together with Shanghai Stock Exchange (SSE), Shenzhen Stock Exchange (SZSE), and China Securities Index (CSI), our company participated in the 
        research project on ESG disclosure standards initiated by the Shanghai Finance Institute and the revision work of the AMAC Green Investment Guidelines. 
        Based on our in-depth research on sustainability in China's capital market, we support and promote the implementation of a series of international ESG 
        and sustainability principles in the Chinese market, thus boosting the development of China’s sustainability policies and practices, satisfying the needs
         of domestic and foreign investors and enterprises in engaging with responsible investment, as well as low-carbon and sustainable development.
        </p></br>
        <p>GSG is one of the first professional service firms in China to focus on ESG and sustainability. The Founder of GSG, Dr. Wang Dequan, is a founding 
        board member of China Finance 40 Forum. He currently serves as an expert of the Green and Sustainable Investment Committee of Asset Management 
        Association of China (AMAC), an expert advisor of the ESG Professional Committee of Insurance Asset Management Association of China, and the deputy 
        director of the Sustainable Investment Committee of the Chinese Asset Management Association of Hong Kong.</p>
        `,

    link: 'Governance Solutions Group (GSG)',
  },
  aboutNex: {
    title: 'ABOUT NEW ENERGY NEXUS',
    section1: `New Energy Nexus (NEX) is a global nonprofit supporting diverse climate entrepreneurs to 
    accelerate the transition to 100% clean energy for 100% of the population. New Energy Nexus provides funds, 
    accelerators and networks to drive climate tech innovation and adoption.`,
  },
  aboutProject: {
    title1: 'What is Climate-Friendly Investment Product project',
    section1: `The 14th FYP period is an important opportunity for China to respond to climate change, 
        and the Ministry of Ecology and Environment requires that the objectives and tasks of climate change be fully integrated into ecological environmental protection planning.
        The Climate-Friendly Investment Product project is a database of China‘s climate-friendly products developed and established by the Governance solutions Group (GSG) in 2022. It is the first financial product database in China that focuses on climate-friendly attributes 
        and defines "climate-friendly investment products" based on international cutting-edge screening methods. 
        The project aims to assess the level of climate friendliness of current investment products in the Chinese market 
        and promote the development of climate investment.
        `,
    // The project is supported by New Energy Nexus, a non-profit 
    // international organization dedicated to creating a sustainable world with 100% clean energy for 100% of the population 
    // in a short period of time.

    title2: 'Bringing a new perspective to sustainable investment in China',
    section2: `The announcement of carbon peak and carbon neutrality goals (hereinafter referred to as “dual carbon goals”) has accelerated the development 
        of investment products associated with sustainability, environmental protection, ESG and other concepts, providing more investment options for responsible investors. 
        Investors still confront to challenges as products are less developed, inadequately disclosed, and there is no clear definition of the 'climate-friendly' . 
        Yet investors with more stringent climate-friendly investment preferences will still need to undertake a more cumbersome screening analysis and will be limited by the current lack of product richness and disclosure levels.
        The Climate-Friendly Investment Product project has created a platform to showcase 'climate-friendly' investment products in China. We currently cover nearly 
        300 "climate-related" thematic funds and 1,600+ green bonds, and based on cutting-edge international methodologies, we have selected investment products from these 
        green-related concept investment products that we believe have significant "climate-friendly" attributes. 
        We have published the specific assessment information and the result on our website to provide a channel for financial practitioners, investors and the public who 
        are concerned about climate change issues to understand the level of climate-friendly attributes of domestic investment products. This aims to promote the 
        development of climate investment and to bring a new perspective to sustainable investment in China.
        `,

    title3: 'Leading the way in low carbon living and investment',
    section3: `The current global average temperature is estimated to be 1.1°C above pre-industrial levels and, based on current trends, global temperatures could 
        break the 1.5°C threshold within the next 20 years and the 2°C threshold earlier in the second half of this century. The investments we make in the next decade 
        will determine whether global temperatures can be kept within 1.5°C. In line with the Paris Agreement, we have brought out a landmark China Climate Catalogue, 
        which aims to educate people on the meaning and value of climate 
        investment, while guiding them to change their traditional way of production and living and usher in a new era of low carbon living.`,

    title4: 'The Synthesis Report of Climate-Friendly Investment Products in China',
    text: `
        <p>To help global investors who focuses on climate-friendly elements to understand investment opportunities in the Chinese market, 
        and at the same time promoting China-based issuers attention to this emerging investment demand, relevant incentive as well as regulatory policies, 
        the GSG project team (hereinafter referred to as “the project team”) attempted to summarize and analyze the current situation of climate-friendly 
        investment products in the mainland of China market(hereinafter referred to as “domestic market” ).
        </p></br><p>
        Considering the lack of a unified and clear definition of climate-friendly investment products in the current market, this research will expand the scope of
         analysis to climate-friendly related investment products, while comparing market development of the mainland of China with developed markets such as the European 
         Union((hereinafter referred to as “EU”), the United States((hereinafter referred to as “U.S.”) and Hong Kong Special Administrative Region (hereinafter referred to as 
            “Hong Kong”). 
        </p></br><p>
        The project team would also summarize the definitions of climate-friendly related investment products, market situations and development trends in these 
            markets. The research outcomes will build a solid foundation for the project team to explore the future trend of climate-friendly investments and develop a database 
            for climate-friendly investment projects.
        </p>
        `,
  }
}
<template>
  <!-- 顶部菜单 -->
  <el-menu
    mode="horizontal"
    router
    :default-active="activeMenu"
    class="el-menu-dl"
    background-color="#112445"
    text-color="#fff"
    active-text-color="#fff"
  >
    <template v-for="(item, index) in topMenus">
      <!-- 无子菜单 -->
      <el-menu-item
        v-if="!item.children"
        :index="item.path"
        :key="index"
        @click.native="handleSelect(item)"
        class="menu-no-child"
      >
        <span v-if="$store.getters.language === 'en_us'">
          {{ item.titleEn }}</span
        >
        <span v-else>{{ item.title }}</span>
      </el-menu-item>

      <!-- 下拉菜单 -->
      <el-submenu
        v-else
        :index="item.path"
        :key="index"
        class="dropdown-container"
        :class="activeMenu === item.path ? 'is-active' : ''"
      >
        <!-- 一级菜单 -->
        <template slot="title">
          <span v-if="$store.getters.language === 'en_us'">
            {{ item.titleEn }}</span
          >
          <span v-else>{{ item.title }}</span>
        </template>

        <!-- 二级菜单 -->
        <el-menu-item
          class="dropdown-li"
          v-for="dropdownItem in item.children"
          :index="dropdownItem.path"
          :key="dropdownItem.path"
          :class="
            currentRouteSplice.includes(dropdownItem.name) ? 'is-active' : ''
          "
          @click.native.prevent="handleSelect(dropdownItem)"
        >
          <span v-if="$store.getters.language === 'en_us'">
            {{ dropdownItem.titleEn }}</span
          >
          <span v-else>{{ dropdownItem.title }}</span>
        </el-menu-item>
      </el-submenu>
    </template>
  </el-menu>
</template>
<script>
// import { constantRoutes } from "@/router";
export default {
  data() {
    return {
      topMenus: [
        {
          title: "首页",
          titleEn: "Home",
          path: "/home",
          name: "Home",
          meta: { title: "Home" },
        },
        {
          title: "气候友好型投资产品目录",
          titleEn: "Climate-Friendly Investment Product",
          path: "/frinedly",
          children: [
            {
              title: "基金",
              titleEn: "Funds",
              name: "funsList",
              path: "/frinedly?activeName=FunsList&radioType=1001",
              meta: { title: "Library", activeMenu: "/frinedly" },
            },
            {
              title: "债券",
              titleEn: "Bonds",
              name: "bondsList",
              path: "/frinedly?activeName=BondsList&radioType=1002",
              meta: { title: "Library", activeMenu: "/frinedly" },
            },
            {
              title: "银行零售产品",
              titleEn: "Bank Retail Products",
              name: "banksFinancial",
              path: "/frinedly?activeName=banksFinancial&radioType=1003",
              meta: { title: "Library", activeMenu: "/frinedly" },
            },
          ],
        },
        {
          title: "方法学说明",
          titleEn: "Methodology",
          path: "/more",
          name: "MethodsPage",
          meta: { title: "Methods" },
        },
        {
          title: "FAQ",
          titleEn: "FAQ",
          name: "FAQ",
          path: "/QA",
        },
        {
          title: "关于项目",
          titleEn: "About",
          path: "/about",
          name: "About",
          // children: [
          //   {
          //     title: "project",
          //     name: "project",
          //     path: "/about/aboutProject",
          //     meta: { title: "project", activeMenu: "/about" },
          //   },
          // {
          //   title: "team",
          //   name: "team",
          //   path: "/about/aboutTeam",
          //   meta: { title: "team", activeMenu: "/about" },
          // },
          // ],
        },
        {
          title: "联系我们",
          titleEn: "Contact Us",
          name: "Contact",
          path: "/contact",
        },
      ],
      currentRouteSplice: "",
    };
  },
  computed: {
    // 默认激活的菜单
    activeMenu() {
      const path = this.$route.path;
      this.currentRouteSplice = path.split("/");

      const route = this.$route;
      const { meta } = route;
      let subActiveMenu = "";

      if (meta.activeMenu) {
        subActiveMenu = meta.activeMenu;
        // this.activeRoutes(subActiveMenu); //激活高亮菜单
        return meta.activeMenu;
      }

      let activePath = path;
      if (path !== undefined && path.lastIndexOf("/") > 0) {
        const tmpPath = path.substring(1, path.length);
        activePath = "/" + tmpPath.substring(0, tmpPath.indexOf("/"));
      } else if (!this.$route.children) {
        activePath = path;
      }
      // this.activeRoutes(activePath); //高亮路由
      return activePath; //返回路由
    },
  },
  methods: {
    handleSelect(obj) {
      // console.log(obj, "menu");
      // let key = obj.name;
      // const route = this.topMenus.find((item) => {
      //   if (item.name === key) {
      //     return item;
      //   } else if (item.children && item.children.length > 0) {
      //     return item.children.find((c) => {
      //       return c.name === key;
      //     });
      //   }
      // });
      // if (this.ishttp(key)) {
      //   window.open(key, "_blank"); // http(s):// 路径新窗口打开
      // }
    },
    //激活路由
    // activeRoutes(key) {
    //   var routes = [];
    //   if (this.childrenMenus && this.childrenMenus.length > 0) {
    //     this.childrenMenus.map((item) => {
    //       if (key == item.parentPath || (key == "index" && "" == item.path)) {
    //         routes.push(item);
    //       }
    //     });
    //   }
    // },
    ishttp(url) {
      return url.indexOf("http://") !== -1 || url.indexOf("https://") !== -1;
    },
  },
};
</script>
<style lang="scss">
</style
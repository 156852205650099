<template>
  <div class="footer-tab">
    <div class="main_tabs">
      <p class="tab-label">{{$t('homeFooter.contact')}}：</p>
      <div
        class="main_tab"
        :class="number == item.value ? 'active' : ''"
        v-for="item in tabList"
        :key="item.value"
        :label="item.value"
        @click="changeTab(item)"
      >
        {{ item.label }}
      </div>
    </div>
    <div class="content">
      <div v-for="item in tabContents" :key="item.value">
        <div v-show="item.value == number">
          <p class="middle-content">
            <span class="tab-label"> {{$t('homeFooter.email')}}：</span>
            <span> {{ item.email }}</span>
          </p>
          <p>
            <span class="tab-label"> {{$t('homeFooter.address')}}： </span>
            <span> {{ item.address }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["tabList", "tabContents"],
  data() {
    return {
      number: 1,
    };
  },
  methods: {
    changeTab(item) {
      this.number = item.value;
    },
  },
};
</script>
<style scoped lang='scss'>
.main_tabs {
  >.tab-label{
    text-align: left;
    min-width: 75px;
  }
  > p {
    padding: 5px 0;
  }
  display: flex;
  .main_tab {
    padding: 5px 10px;
    cursor: pointer;
  }
  .main_tab.active {
    font-weight: 800;
  }
}
.content > div {
  text-align: left;
}
.content {
  .middle-content {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
</style>
// 附录

export default {
    appendix1Title: {
        title1: `Methodology for the screening of climate-friendly investment products`,
        title2: `Appendix 1: Industry labels from climate-friendly economic activities list`,
        title3: `Table 1 climate-friendly economic activities (55 items)`,
    },
    appendix1Head:
    {
        tableTh1: 'Serial number',
        tableTh2: 'Solution',
        tableTh3: '中文翻译',
    },
    appendix1Table: [
        { original: 'Abandoned Farmland Restoration' },
        { original: 'Alternative cement' },
        { original: 'Alternative Refrigerants' },
        { original: 'Biogas for Cooking' },
        { original: 'Biomass Power' },
        { original: 'Bioplastics' },
        { original: 'Building Automation Systems' },
        { original: 'Building Retrofitting' },
        { original: 'Carpooling' },
        { original: 'Clean Cooking' },
        { original: 'Coastal Wetland Restoration' },
        { original: 'Concentrated Solar Power' },
        { original: 'Distributed Energy Storage' },
        { original: 'Distributed Solar Photovoltaics' },
        { original: 'District Heating' },
        { original: 'Dynamics Glass' },
        { original: 'Efficient Aviation' },
        { original: 'Efficient Ocean Shipping' },
        { original: 'Efficient Trucks' },
        { original: 'Efficient Bicycles' },
        { original: 'Electric Cars' },
        { original: 'Farm Irrigation Efficiency' },
        { original: 'Forest Protection' },
        { original: 'Geothermal Power' },
        { original: 'Grid Flexibility' },
        { original: 'High-Efficiency Heat Pumps' },
        { original: 'High-Performance Glass' },
        { original: 'High-Speed Rail' },
        { original: 'Hybrid Cars' },
        { original: 'Improved Aquaculture' },
        { original: 'Insulation' },
        { original: 'Landfill Methane Capture' },
        { original: 'LED Lighting' },
        { original: 'Microgrids' },
        { original: 'Net-Zero Buildings' },
        { original: 'Nuclear Power' },
        { original: 'Offshore Wind Turbines' },
        { original: 'Onshore Wind Turbines' },
        { original: 'Peatland Protection and Rewetting' },
        { original: 'Public Transit' },
        { original: 'Recycled Metals' },
        { original: 'Recycled Paper' },
        { original: 'Recycling' },
        { original: 'Reduced Food Waste' },
        { original: 'Refrigerant Management' },
        { original: 'Regenerative Annual Cropping' },
        { original: 'Small Hydropower' },
        { original: 'Smart Thermostats' },
        { original: 'Sustainable Intensification for Smallholders' },
        { original: 'Telepresence' },
        { original: 'Utility-Scale Energy Storage' },
        { original: 'Utility-Scale Solar Photovoltaics' },
        { original: 'Waste-to-Energy' },
        { original: 'Water Distribution Efficiency' },
        { original: 'Large scale hydropower' }
    ],

    // 附录2
    appendix2Title: {
        title1: `Methodology for the screening of climate-friendly investment products`,
        title2: `Appendix 2: Comparison of fossil fuel screening standard`,
    },
    appendix2Head:
    {
        tableTh1: 'Economic activity',
        tableTh2: 'Standard 1 (Best-in-class)',
        tableTh3: 'Standard 2',
    },
    appendix2Table: [{
        standard: 'Coking Coal Production',
        SFDR: '×',
        fossilFree: '√',
    }, {
        standard: 'Thermal coal mining',
        SFDR: '√',
        fossilFree: '√',
    }, {
        standard: 'Oil and gas exploration, production, manufacturing, processing, marketing and transportation, marketing, and transportation',
        SFDR: '√',
        fossilFree: '√',
    }, {
        standard: 'Services and equipment for oil and gas exploration',
        SFDR: '×',
        fossilFree: '√',
    }, {
        standard: 'Thermal power generation and thermal power sales, transmission and distribution business',
        SFDR: '×',
        fossilFree: '√',
    },
    ]
}


// 首页
export default {
    homeTop: {
        title1: `
        <p>如果我们不采取措施积极</p>
        `,
        title2: '「减少碳排放」',
        title3: `
        <p>地球气温将持续上升</p>
        `,
        // temperatureText1: '<p>的生物可能面临灭绝</p>',
        // temperatureText2: '<p>每年受洪水侵袭的人数</p><p>可能增加3亿人</p>',
        // temperatureText3: '<p>中国人面临供水安全问题</p>',
        temperatureText4: `《巴黎协定》提出的目标是要把全球平均气温升幅控制在工业革命前水平以上低于2℃之内，并努力将气温升幅限制在工业化前水平以上1.5℃之内，我们需要为这一目标付出实质的努力。
        `,
        // up: '上升'
    },
    homeTrendList: [{
        iconClass: "home-tree",
        trend: '上升',
        temp: "3℃",
        num: "50%",
        impair: '生物可能面临灭绝'
    }, {
        iconClass: "home-people",
        trend: '上升',
        temp: "4℃",
        num: "300,000,000",
        impair: '每年受洪水侵袭的人数可能增加3亿人'
    }, {
        iconClass: "home-water",
        trend: '上升',
        temp: "5℃",
        num: "25%",
        impair: '中国人面临供水安全问题'
    }],
    section1: {
        title: '能源控制是中国碳中和的重要议题',
        desc: `<div>
        <strong>「近些年来我国政策端对化石能源逐渐收紧，社会迫切需要开发高质量的气候解决方案。」</strong>
        
        <p>
        </br>
        “十五”期间可再生能源可享受税收优惠；
        “十一五”诞生了我国第一部鼓励可再生能源的重要法律，并建立了促进可再生能源发展的国家基金，风电光电上网电价补贴制度也在这一时期实施；
        “十二五”启动了碳排放交易试点，同时国务院发布了《空气污染防治行动计划》；“十三五”出台的煤炭使用上限为可再生能源的过渡提供强力的指导和确定性；
        “十四五”规定了能源消费总量和排放总量，将新能源确定为战略性新兴产业，强调要加快能源市场改革，推进低碳能源投资。
        </p>
        </div>`,
    },
    section2: {
        title: '低碳能源是中国碳中和的必经之路',
        desc: `<div>
        <strong>「我国非化石能源占比稳步上升。」</strong>
        <p>
        </br>
        我国在联合国气候变化峰会上承诺2030年NDC(National Determined Contribution)目标包括非化石燃料在能源消费的占比提高到25%左右，
        国务院印发《关于完整准确全面贯彻新发展理念做好碳达峰碳中和工作的意见》 提及我国到2060年，绿色低碳循环发展的经济体系和清洁低碳安全高效的能源体系全面建立，
        能源利用效率达到国际先进水平，非化石能源消费比重达到80%以上。 
        </p>
        </div>`,
    },
    section3: {
        title: '我们正在助力碳中和',
        // product:'Climate Friendly Investment Product',
        desc: `随着近年来全球对气候变化议题重视程度的持续提升，越来越多的投资者开始关注投资活动的气候影响和气候风险，并寻求将资金投向与全球应对气候变化目标相一致的方向。
        Climate Friendly Investment Product是国内首个气候友好投资产品数据库，我们从是否支持气候解决方案型经济活动和是否支持化石燃料相关经济活动两个方面，
        考察评估投资产品的气候友好属性。通过公开我们的评估结果，促进气候投融资发展，助力碳达峰和碳中和。
        `,
        btn1: '进一步了解项目',
        methods1: `<p>什么是气候解决方案?</p>
        <p>我们认为符合气候解决方案属性的55个行业</p>
       `,
        btn2: '了解评估方法',
        methods2: `<p>什么是涉及化石燃料?</p>
        <p>我们认为符合Fossil Free Fund或者SFDR定义的行业为涉及化石燃料</p>
       `,
    },
    section4: {
        someTips1: "纳入筛选范围的投资产品规模：",
        title1: '基金总规模',
        title2: '基金数量',
        title3: '债券总规模',
        title4: '债券数量',
        fundTotal: '3,146',
        unit1: ' 亿元',
        bondNumber: '299',
        unit2: ' 只',
        bondTotal: '31,495',
        unit3: ' 亿元',
        fundNumber: '1,620',
        unit4: ' 只',
        linkText1: '查看完整气候友好型基金列表',
        linkText2: '查看基金如何筛选',
        linkText3: '查看完整气候友好型债券列表',
        linkText4: '查看债券如何筛选',
    },
    section5: {
        legalNotice: '免责声明',
        content: `本网站介绍的信息、工具和资料仅供一般性参考，除非另有明确协议，不应被视为购买或销售任何金融产品的某种要约，亦非对任何交易的正式确认。
        本网站介绍的信息、工具和资料并非旨在提供任何形式的建议，包括但不限于投资、税收、会计或法律上的建议。投资有风险，基金的过往业绩并不预示其未来表现，
        您在作出投资决策前应认真阅读产品招募说明书及其在指定信息披露媒体上发布的正式公告和有关信息，并自行承担投资风险。`,
        more: '查看完整免责声明'
    },

    homeFooter: {
        publicAccount: '鼎力订阅号',
        aboutPro: '关于项目',
        contact: '联系我们',
        link: '鼎力官网',
        suggest: '意见建议',
        legal: '法律声明',
        email: '联系邮箱',
        address: '工作地址',
        copyright: 'CopyRight © 鼎力可持续数字科技（深圳）有限公司'
    },
    homeTabList: [
        { label: "深圳", value: 1 },
        { label: "北京", value: 2 },
        { label: "上海", value: 3 },
        { label: "香港", value: 4 },
    ],
    homeTabContents: [
        {
            label: "深圳",
            email: "inquiry@governance-solution.com",
            address: "深圳市福田区金田路4028号荣超经贸中心2410室",
            value: 1,
        },
        {
            label: "北京",
            email: "inquiry@governance-solution.com",
            address: "北京市朝阳区建国路112号中国惠普大厦1101-3室",
            value: 2,
        },
        {
            label: "上海",
            email: "inquiry@governance-solution.com",
            address: "上海市浦东新区张杨路500号华润时代广场12楼",
            value: 3,
        },
        {
            label: "香港",
            email: "inquiry@governance-solution.com",
            address: "香港中环渣打道3A香港会所大厦16楼",
            value: 4,
        },
    ],
    elevator: {
        connect1: '联系邮箱',
        connect2: '微信联系',
    },
    // 联系邮件-弹窗
    connectDia: {
        name: '姓名：',
        placeholderName: '请输入姓名',
        company: '公司：',
        placeholderCompany: '请输入公司',
        role: '职位：',
        placeholderRole: '请输入职位',
        phone: '联系电话：',
        placeholderPhone: '请输入电话',
        email: '联络邮箱：',
        placeholderEmail: '请输入邮箱',
        emailSubject: '邮件主题：',
        placeholderSubject: '请输入邮件主题',
        content: '邮件内容：',
        placeholderContent: '请输入邮件内容',
        cancel: '取 消',
        submit: '发送邮件',
        errorMsg1: '不能为空',
        errorMsg2: '请输入正确的邮箱地址',
        successMsg1: '发送成功',
        sendingMsg: '发送中...'
    }
}
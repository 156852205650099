// 法律声明
export default {
    legalTop: {
        title: 'Legal'
    },
    legalSection1: {
        title: "Terms of Use",
        content1: `These Terms of Use apply to the website(https://www.climatefriendlyinvest.com/). Your use of this website constitutes your 
        acceptance of and agreement to these Terms of Use. To the extent permitted by law, GSG reserves the right to change the content of 
        these Terms of Use at any time in its sole discretion. It is your responsibility to review these Terms of Use periodically for 
        changes. Your continued use of the site after updates are posted constitutes your agreement and acceptance of such changes.`,

        title2: "Trademarks and Copyrights",
        content2: `
        <p>All text, images, music, video, animation, trademarks, graphics, diagrams, visual interfaces and computer code ("Content") 
        contained on this site, including but not limited to the idea, design, structure, presentation and results of the content, are owned, controlled or 
        legally authorized for use by GSG. Except as specifically allowed in these Terms of Use, you may not use the content of this website for any commercial 
        purpose without GSG's prior written authorization. Any authorized viewing, copying, printing and distribution of the content of the materials within 
        this website is subject to the following conditions.
        </p>
        <p>- For personal study and research use only, not for commercial purposes.</p>
        <p>- That no modifications are made to the content of the material</p>
        <p>- The reproduction of any part or full use of the content must include GSG's copyright notice or other copyright notices contained in 
        the original content.</p>
        <p>The intellectual property rights in the content provided on this site or made available to you as a result of your use, are 
        owned by the company or other relevant rights holders. The trademarks, trade names, and logos (collectively, the Trademarks) used 
        and appearing on the site are registered and unregistered trademarks of the company and other related rights holders</p>
        <p>Nothing on the site should be construed as granting the user permission or the right to use any trademark. Users are not permitted to 
        use trademarks identical to these trademarks without the consent of the company or other related rights holders.
        </p>
        `,

        title3: "Usage Specification",
        content3: `
        You may not use the site for any unlawful or prohibited purpose by these Terms of Use, and you may not use the site or its content 
        to infringe upon the legal rights of other organizations or individuals. You may not use any device, program, algorithm or any manual 
        process of any kind, including spider, robot, deep-link, page-scrape, or any exact or similar process, to access, acquire, copy or monitor 
        any portion of the site or any content, or to access, receive or copy any material, document or information from the site by any means other 
        than those provided by the site. You may not access, obtain, or copy any material, file or information on the site by any means other than those 
        provided by the site. You may not attempt to gain unauthorized access to any content on the site or use any services on the site through 
        trespassing, password cracking, or any other illegal means. You may not trace, reverse lookup, decrypt, or decipher any customer's information on 
        the site, including, but not limited to, any accounts not owned by you. You may not breach any network security mechanism or authentication 
        measures on the site or linked to the site, detect, scan or test any network vulnerability on the site or related to the site, nor may you launch 
        any cyber attack on the site. You agree not to use any device, software or routine that interferes or attempts to interfere with the proper 
        functioning of the site or any transaction conducted on the site or interferes or attempts to interfere with another person's use of the site. 
        You shall not take any action to store an unreasonable or disproportionately large amount of data on the site infrastructure, systems or networks, 
        or on systems or networks linked to the site.
        `,

        'title3-1': "Usage of Information and Materials on the Site",
        'content3-1': `The content of this website is intended to provide general information on one or more specific topics,
         is not a thorough analysis of those topics, and does not constitute professional advice or services, legal or otherwise, to any user. 
         Users of this website should seek the advice of relevant professionals, where necessary, before making any investment decision or taking any 
         action. The company shall not be liable for any loss experienced by any user due to using or relying on the information on this website.
        `,

        title4: "Disclaimer",
        content4: `<p>The company makes no representations or warranties that</p>
        <p>- this website is available and meets your requirements.</p>
        <p>- site use will be uninterrupted, without delay, malfunction, error or omission or loss of transmitted information.</p>
        <p>- no viruses or other contaminating or destructive products will be transmitted; or </p>
        <p>- your computer system will not be damaged.</p>
        <p>It is your sole responsibility to ensure adequate data and/or equipment protection and back-up, and to take reasonable and appropriate precautions 
        to scan for computer viruses or other destructive products. The company has exercised due care in compiling the information and materials contained on 
        this website. However, the company can only provide the data as it is currently available without any express or implied warranty. In particular, 
        no guarantee of non-infringement of security, accuracy, suitability for a particular purpose, or freedom from computer viruses is given in connection 
        with the content of such information.</p>
        `,

        title5: "External Links",
        content5: `
        <p>Some links in this website may lead to other independent third-party websites, and such links are provided solely as a convenience to users. GSG makes no explicit or implied guarantees about the content, products, services, information, etc., of the linked websites, nor are such links considered to be a recommendation or authorization by GSG of the linked websites. You are responsible for making independent judgments regarding your interactions with the linked sites, and GSG assumes no responsibility for such interactions.
        </p>
        `,

        title6: "Retention of Rights",
        content6: `<p>GSG reserves the right, at any time and without notice, to</p>
        <p>- Suspend or terminate the operation of and access to all or part of this website for any reason.</p>
        <p>- Modify or change all or part of the site and its applicable policies or terms.</p>
        <p>- Discontinue the operation of and access to all or part of the site when necessary to perform scheduled or unscheduled maintenance, error correction or other changes.</p>
        `,

        title7: "Others",
        content7: `
        <p>These Terms of Use constitute the entire agreement between you and GSG about your use of the site and hereby supersede any pre-existing agreements, written or oral, between you and GSG regarding your use of the site. GSG does not accept any counter-offer to these Terms of Use, except as provided in other agreements between you and GSG, and all counter-offers are hereby expressly rejected.</p>
        <p>GSG's failure to insist upon or enforce strict performance of these Terms of Use shall not be deemed a waiver of any of these Terms of Use or any right to enforce these Terms of Use, and any act between GSG and you or any other third party shall not be deemed a modification of any provision of these Terms of Use.<p>
        <p>If any of the preceding provisions are determined to be unenforceable for any reason, none of the other conditions are affected, and the other provisions remain in effect.</p>
        `,
    },
    // 免责声明
    legalSection2: {
        title: "Disclaimers",
        content1: `
        <p>Please read the following terms and conditions carefully. By accessing, visiting or using this website and any of part of this site, you agree to be bound by these terms and conditions. The following terms and conditions are subject to change. If you do not agree to them, please do not access, visit or use any information of this website.</p>
        <p>“The Website” refers to the climate-friendly investment products website (https://www.climatefriendlyinvest.com/) owned and published by Dingli Sustainable Digital Technology (Shenzhen) Co., ltd.(hereinafter referred to as "the Company") and all the relevant information and materials contained.</p>
        <p>1. The information and materials contained on this Website results from analysis based on publicly available information of relevant products and the methodology established by the Company in conjunction with New Energy Nexus, a world-leading new energy technology and innovation service company. Although every effort has been made to prepare and provide information and materials contained on the Website, the Company makes no warranty as to the accuracy, adequacy, completeness, or fitness for use of such information and materials. </p>
        <p>2. The information, tools and materials presented on the Website are for general information purposes only and should not be considered as an offer to buy or sell any financial product, nor as a formal confirmation of any transaction, unless otherwise explicitly agreed. The information, tools and materials presented on the Website are not intended to provide advice of any kind, including, but not limited to, investment, tax, accounting or legal advice. Investment involves risks and past returns of a fund are not indicative of its future performance. Please read the product prospectus and its official announcement and related information published in the designated information disclosure media carefully before making any investment decisions and be prepared to bear your own investment risks.</p>
        <p>3. The Company assumes no responsibility or warranty, either express or implied, that any service of this Website will be safe, error-free or uninterrupted. You agree to use the services of this Website at your own risk and be liable for any loss or damage arising from such risks. The Company shall not be liable for any loss of data, etc. caused by technical failures (such as transmission errors, technical defects, third party intrusion, etc.).</p>
        <p>4. The content of this Website will be updated or amended periodically. The Company does not guarantee or warrant the timeliness of the information and materials contained or referred to on the Website. The Company reserves the right to remove or modify information on the Website without prior notice.</p>
        <p>5. Unless there is intentional misconduct or fraud, the Company, its branches, affiliates, directors and employees will not be liable for any direct or indirect damages that may be caused to you as a result of your access, visit or use of the Website, or inability to access, visit or use the Website.</p>
        `,
    }
}
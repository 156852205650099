// 关于我们

export default {
  aboutDL: {
    title: '全球视野 中国智慧',
    leftTitle: '公司介绍',
    section1: `鼎力可持续数字科技公司是一家专注中国市场的ESG投资和可持续发展一站式服务提供商。
        致力于为中国和境外机构投资者提供针对中国上市公司、发债主体、以及授信企业的ESG数据、研究和评级服务；
        为上市公司、产业园区、以及国际供应链里的所有企业提供碳管理SaaS软件平台、碳中和解决方案、可持续发展战略、
        绿色融资和ESG评级改善等专业服务。
        `,

    title2: "全球视野",
    section2: `鼎力是多个国际可持续发展相关倡议的签署方和支持者：`,

    section3List: [{
      name: "联合国责任投资原则组织（UN PRI）",
      content: ` PRI是联合国发起成立的旨在推行ESG投资理念的国际组织，
      致力于实现高效且具有可持续性的全球体系，现已成为全球最有影响力的ESG投资倡议。鼎力是中国最早加入PRI的服务机构之一`
    }, {
      name: "全球报告倡议组织（GRI）",
      content: `GRI制定的《可持续发展报告指南》是目前全球使用最广泛的的可持续发展报告标准，
      旨在帮助企业披露与其所有利益相关者有实质性影响的可持续发展主题。`
    }, {
      name: "可持续会计准则委员会（SASB）",
      content: `SASB为不同行业针对性地开发ESG关键绩效指标，制定可持续会计准则，
      促进全球企业披露对财务表现有实质性影响且有助于投资决策的相关信息。`
    }, {
      name: "气候相关财务披露工作组（TCFD）",
      content: `TCFD由金融稳定委员会（FSB）组成，以应对气候相关金融风险披露方面的严重差距。
      TCFD向金融机构和非金融公司在四个领域提供气候变化披露建议：治理、战略、风险管理、指标和目标`
    }, {
      name: "全球环境信息研究中心（CDP）",
      content: `CDP是目前最权威的基于投资人和采购方需求的碳披露平台。目前，CDP与全球590余家总资产达110万亿美元的投资人，
      以及数百家大型跨国企业合作，通过投资者和买家的力量激励企业披露和管理其环境影响。作为CDP全球银级战略伙伴，
      鼎力公司被认可在以下领域为中国的外向型企业提供解决方案：
      CDP报告编制、碳排放测算、投资组合碳足迹测算、SBT（科学碳目标）设定、可持续发展战略制定、TCFD报告支持。`
    }],

    section3: `
    <p><strong>• 联合国责任投资原则组织（UN PRI）：</strong> 
    PRI是联合国发起成立的旨在推行ESG投资理念的国际组织，
        致力于实现高效且具有可持续性的全球体系，现已成为全球最有影响力的ESG投资倡议。鼎力是中国最早加入PRI的服务机构之一。</p></br></br>
       
        <p><strong>• 全球报告倡议组织（GRI）：</strong>GRI制定的《可持续发展报告指南》是目前全球使用最广泛的的可持续发展报告标准，
        旨在帮助企业披露与其所有利益相关者有实质性影响的可持续发展主题。</p></br></br>
        
        <p><strong>• 可持续会计准则委员会（SASB）：</strong>SASB为不同行业针对性地开发ESG关键绩效指标，制定可持续会计准则，
        促进全球企业披露对财务表现有实质性影响且有助于投资决策的相关信息。</p></br></br>
        
        <p><strong>• 气候相关财务披露工作组（TCFD）：</strong>TCFD由金融稳定委员会（FSB）组成，以应对气候相关金融风险披露方面的严重差距。
        TCFD向金融机构和非金融公司在四个领域提供气候变化披露建议：治理、战略、风险管理、指标和目标。</p></br></br>
        
        <p><strong>• 全球环境信息研究中心（CDP）：</strong>CDP是目前最权威的基于投资人和采购方需求的碳披露平台。目前，CDP与全球590余家总资产达110万亿美元的投资人，
        以及数百家大型跨国企业合作，通过投资者和买家的力量激励企业披露和管理其环境影响。作为CDP全球银级战略伙伴，
        鼎力公司被认可在以下领域为中国的外向型企业提供解决方案：
        CDP报告编制、碳排放测算、投资组合碳足迹测算、SBT（科学碳目标）设定、可持续发展战略制定、TCFD报告支持。</p>
        </br></br>

        <h3>中国智慧</h3>
        </br>
        <p>鼎力是中国最早专注ESG和可持续发的专业服务机构之一，给人王德全是中国金融四十人论坛的创始理事，目前担任中国基金业协会绿色和可持续投资委员会专家、
        中国保险营协会ESG专业委员会专家顾，香港中资基全业协会可持续投资委员会主任，鼎力团队成员来自中节能 中创投、讯、百度，众银行、湖州银行、麦肯提、
        普华永道等机构公司与上交所、深交所，中证指数公司一起参与了上海新全最研究院关于中国ESG城露标的课题，以及基全业协会色投资指3 的修订工作，其于我们对中
        国本市场可特速发展研究的深耕，我支持并是进系列国际生的ESG和可持续发展原则在中市场的落地，从而助力中国市场的可持续发展制度建设和实践发展，进而满足内外
        投资人和企业在践行责任投资和低碳，可持续发展的需求。</p>
        `,
    link: '鼎力官网',
  },



  aboutNex: {
    title: '关于NEW ENERGY NEXUS',
    section1: `New Energy Nexus 于2016年成立，起源于加州，目前已联结全球30个国家的90多个清洁能源孵化器与加速器、投资机构、国际机构，在中国、印度、新加坡、菲律宾、印度尼西亚、泰国、越南、乌干达、尼日利亚、美国等国家及地区运营创新项目。
    作为国际机构， New Energy Nexus 致力于清洁能源技术的发掘、流通、普及与应用，为全球清洁能源初创企业家提供资金、项目、生态网络支持。`,
  },
  aboutProject: {
    title1: 'Climate Friendly Investment Product 是什么',
    section1: `“十四五”期间是我国应对气候变化的重要机遇期，生态环境部要求将应对气候变化目标任务全面融入生态环境保护规划。
        Climate Friendly Investment
        Product项目是2022年由鼎力可持续数字科技团队开发建立的中国气候友好型产品数据库，是国内首个聚焦于气候友好属性的的金融产品
        数据库，基于国际前沿的筛选方法定义了“气候友好投资产品”的内涵，旨在梳理评估当前中国市场中投资产品的气候好友水平，并促进气候投资发展。
       
      `
    // 该项目由New Energy Nexus支持， New Energy Nexus是一个非营利性的国际组织，致力于在短时间内为100％的人口创造100％清洁能源的
    // 可持续发展的世界。
    ,
    title2: '为中国可持续投资带来新视角',
    section2: `在“双碳”目标的引领下，我国绿色金融发展迅猛，市场中可持续、环保、ESG等相关概念的投资产品层出不穷、规模快速增长，可供有负责任投资需求的投资人进行投资的选择逐渐增多，但是对于“气候友好”这一细分概念，目前尚缺乏明确的定义，但投资人若有更严格的气候友好投资偏好，目前仍需进行较繁琐的筛选分析，且会受限于当前较为不足的产品丰富程度和披露水平。
      Climate Friendly Investment
      Product项目建立了一个展示中国具有“气候友好”属性投资产品的平台。目前我们的评估范围覆盖了近300支“气候”相关主题基金和1600+支绿色债券，基于国际前沿的方法论，从这些绿色相关概念投资产品中筛选出了我们认为显著具有“气候友好”属性的投资产品。我们将筛选结果和具体评估信息在网站上公开展示，希望可以为关注气候变化议题的金融从业人员、投资者、公众提供一个了解国内投资产品气候友好属性水平的渠道，促进气候投资的发展，为中国可持续投资带来新视角。
    `,
    title3: '引领低碳生活和投资理念',

    // section3: ` 据估计目前全球平均气温比工业化前(Pre-industrial
    //     level)高出1.1°C，根据现有趋势，全球气温可能在未来20年内突破1.5°C门槛，
    //     并在本世纪下半叶提前突破2°C门槛。我们在未来十年做出的投资将决定全球气温能否控制在1.5°C
    //     以内。我们遵循《巴黎协定》带来了划时代的中国气候产品目录，旨在帮助人们理解气候投资的意义与价值同时引导
    //     人们改变原有生产与生活方式，开启低碳生活的新时代。
    //   `,

    title4: '中国气候友好型投资产品综述分析报告',
    text: `<p>为帮助全球范围内重视气候友好因素的投资者了解中国市场相关投资机会，
    同时促进国内投资产品的发行供给方关注这一类逐渐兴起的投资需求和相关的鼓励、监管政策，
    鼎力项目组尝试对中国市场气候友好型产品的现状进行梳理研究，并撰写本研究报告。
    </p></br><p>
    考虑到当前市场中尚缺乏统一、明确的气候友好型投资产品概念，本研究将分析范围扩展到气候友好相关概念投资产品，
    并将中国大陆市场情况与欧盟、美国、中国香港等境外发达市场进行对比，梳理气候友好相关投资产品的概念定义、
    市场现状和发展趋势。
    </p></br><p>
    该研究成果为项目组探讨气候友好型投资的未来发展趋势并开发气候友好型投资项目库奠定了基础。</p>
    `,
  }
}
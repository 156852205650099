// 附录

export default {
    appendix1Title: {
        title1: `气候友好型投资产品筛选方法学`,
        title2: `附录1：气候友好经济活动目录（55项）`,
        title3: `表1 气候友好经济活动目录（55项）`,
    },
    appendix1Head:
    {
        tableTh1: '序号',
        tableTh2: '原文',
        tableTh3: '中文翻译',
    },
    appendix1Table: [
        { original: 'Abandoned Farmland Restoration', chineseTranslation: '废弃农田恢复', },
        { original: 'Alternative cement', chineseTranslation: '低二氧化碳水泥', },
        { original: 'Alternative Refrigerants', chineseTranslation: '替代制冷剂（HFC）', },
        { original: 'Biogas for Cooking', chineseTranslation: '沼气炉灶', },
        { original: 'Biomass Power', chineseTranslation: '生物质能', },
        { original: 'Bioplastics', chineseTranslation: '生物塑料', },
        { original: 'Building Automation Systems', chineseTranslation: '建筑自动化控制系统', },
        { original: 'Building Retrofitting', chineseTranslation: '建筑物改造', },
        { original: 'Carpooling', chineseTranslation: '拼车', },
        { original: 'Clean Cooking', chineseTranslation: '清洁炉灶', },
        { original: 'Coastal Wetland Restoration', chineseTranslation: '湿地恢复', },
        { original: 'Concentrated Solar Power', chineseTranslation: '太阳能光热', },
        { original: 'Distributed Energy Storage', chineseTranslation: '分布式电能储存', },
        { original: 'Distributed Solar Photovoltaics', chineseTranslation: '分布式太阳能光伏', },
        { original: 'District Heating', chineseTranslation: '集中式供暖/制冷系统', },
        { original: 'Dynamics Glass', chineseTranslation: '动态变化的玻璃', },
        { original: 'Efficient Aviation', chineseTranslation: '低碳航空', },
        { original: 'Efficient Ocean Shipping', chineseTranslation: '低碳航运', },
        { original: 'Efficient Trucks', chineseTranslation: '低碳卡车', },
        { original: 'Efficient Bicycles', chineseTranslation: '电动自行车', },
        { original: 'Electric Cars', chineseTranslation: '电动汽车', },
        { original: 'Farm Irrigation Efficiency', chineseTranslation: '高效农业灌溉', },
        { original: 'Forest Protection', chineseTranslation: '保护森林', },
        { original: 'Geothermal Power', chineseTranslation: '基于蒸汽发电系统的地热能', },
        { original: 'Grid Flexibility', chineseTranslation: '更灵活的电网', },
        { original: 'High-Efficiency Heat Pumps', chineseTranslation: '高效率的热泵', },
        { original: 'High-Performance Glass', chineseTranslation: '隔热高性能玻璃', },
        { original: 'High-Speed Rail', chineseTranslation: '高铁', },
        { original: 'Hybrid Cars', chineseTranslation: '混动汽车', },
        { original: 'Improved Aquaculture', chineseTranslation: '低碳水产养殖', },
        { original: 'Insulation', chineseTranslation: '隔热材料', },
        { original: 'Landfill Methane Capture', chineseTranslation: '垃圾场甲烷捕捉', },
        { original: 'LED Lighting', chineseTranslation: 'LED照明', },
        { original: 'Microgrids', chineseTranslation: '微型电网', },
        { original: 'Net-Zero Buildings', chineseTranslation: '0碳建筑物', },
        { original: 'Nuclear Power', chineseTranslation: '核能', },
        { original: 'Offshore Wind Turbines', chineseTranslation: '海上风力发电', },
        { original: 'Onshore Wind Turbines', chineseTranslation: '陆地风力发电', },
        { original: 'Peatland Protection and Rewetting', chineseTranslation: '泥碳地保护', },
        { original: 'Public Transit', chineseTranslation: '公共交通', },
        { original: 'Recycled Metals', chineseTranslation: '金属回收', },
        { original: 'Recycled Paper', chineseTranslation: '再生纸', },
        { original: 'Recycling', chineseTranslation: '回收', },
        { original: 'Reduced Food Waste', chineseTranslation: '减少食物浪费', },
        { original: 'Refrigerant Management', chineseTranslation: '制冷剂（HFC）管理', },
        { original: 'Regenerative Annual Cropping', chineseTranslation: '再生农业', },
        { original: 'Small Hydropower', chineseTranslation: '小型水力发电', },
        { original: 'Smart Thermostats', chineseTranslation: '智能恒温器', },
        { original: 'Sustainable Intensification for Smallholders', chineseTranslation: '小农户高效种地', },
        { original: 'Telepresence', chineseTranslation: '远程开会', },
        { original: 'Utility-Scale Energy Storage', chineseTranslation: '大规模集中式储能', },
        { original: 'Utility-Scale Solar Photovoltaics', chineseTranslation: '大规模集中式光伏', },
        { original: 'Waste-to-Energy', chineseTranslation: '燃烧垃圾产能', },
        { original: 'Water Distribution Efficiency', chineseTranslation: '提高供水效率', },
        { original: 'Large Scale hydropower', chineseTranslation: '大型水电', }
    ],

    // 附录2
    appendix2Title: {
        title1: `气候友好型投资产品筛选方法学`,
        title2: `附录2： 化石燃料筛选标准对比`,
        // title3: `表2 SFDR、Fossil Free Funds判断标准对比`,
    },
    appendix2Head:
    {
        tableTh1: '经济活动',
        tableTh2: '标准1（严格）',
        tableTh3: '标准2',
    },
    appendix2Table: [{
        standard: '炼焦煤生产',
        fossilFree: '√',
        SFDR: '×',
    }, {
        standard: '热煤开采',
        fossilFree: '√',
        SFDR: '√',
    }, {
        standard: '石油和天然气的勘探、生产、制造、加工、销售和运输',
        fossilFree: '√',
        SFDR: '√',
    }, {
        standard: '为油气勘探提供服务和设备',
        fossilFree: '√',
        SFDR: '×',
    }, {
        standard: '火力发电和火电销售、输配业务',
        fossilFree: '√',
        SFDR: '×',
    },
    ]
}


// 首页
export default {
    homeTop: {
        // title: 'What Will Happen If the Temperature of the Earth Continues to Rise?',
        title1: `
        <p>What Will Happen</p>
        `,
        title2: 'If the Temperature of the Earth',
        title3: `
        <p>Continues to Rise?</p>
        `,
        // temperatureText1: '<p>of species could potentially face extinction</p>',
        // temperatureText2: '<p>more people may be</p><p>affected by coastal flooding each year</p>',
        // temperatureText3: '<p>Possible disappearance of large glaciers in Himalayas,</p><p> affecting water supply of  one-quarter of China’s population</p>',
        temperatureText4: `The Paris Agreement is a legally binding international treaty on climate change. 
        Its goal is to limit global warming to well below 2, preferably to 1.5 degrees Celsius, compared to pre-industrial levels.
        `,
        // up: 'Temp rise'
    },
    homeTrendList: [{
        iconClass: "home-tree",
        trend: 'Temp rise',
        temp: "3℃",
        num: "50%",
        impair: 'of species could potentially face extinction'
    }, {
        iconClass: "home-people",
        trend: 'Temp rise',
        temp: "4℃",
        num: "300,000,000",
        impair: 'more people may be affected by coastal flooding each year'
    }, {
        iconClass: "home-water",
        trend: 'Temp rise',
        temp: "5℃",
        num: "25%",
        impair: `Possible disappearance of large glaciers in Himalayas,
        affecting water supply of one-quarter of China‘s population`
    }],
    section1: {
        title: 'Energy control is an important issue for carbon neutrality in China',
        desc: `
        <div>
        <strong>In recent years, China has gradually tightened its policy on fossil fuels, with critical development on high-quality climate solutions</strong>
        <p>
        </br>
        “During the 10 th FYP, renewable energy was given tax preference; 
        the 11 th FYP saw the birth of China‘s first major law to encourage renewable energy and the establishment of a national fund to promote the development of renewable energy, 
        as well as a subsidy system for wind power and solar power feed-in tariffs. The 12th FYP launched a pilot carbon emissions trading scheme, 
        while the State Council issued the Action Plan for Air Pollution Prevention and Control; 
        the 13th FYP introduced a cap on coal use to provide strong guidance and certainty for the transition to renewable energy. 
        The 14th FYP limited the total quantity of energy consumption and emissions, identifying new energy as a strategic emerging industry, 
        and emphasizing the need to accelerate energy reform, while promoting low-carbon energy investment.
        </p>
        </div>`,
    },
    section2: {
        title: 'Low carbon energy is the essential path to carbon neutrality in China',
        desc: `<div>
        <strong>The share of non-fossil energy in China has been steadily increasing.</strong>
       
        <p>
        </br>
        China‘s commitment to the 2030 NDC (National Determined Contribution) target at the UN Climate Change Summit includes increasing 
        the share of non-fossil fuels in energy consumption to around 25%, and the State Council issued the “Working Guidance for Carbon Dioxide Peaking and 
        Carbon Neutrality in Full and Faithful Implementation of the New Development Philosophy” mentioning that by 2060, 
        China will establish and improve an economic system featuring green, low-carbon, and circular development, and build a clean, 
        low-carbon, safe and highly-efficient energy system. Energy utilization efficiency 
        may lead the world, where it’s clean (non-fossil) energy consumption will surpass 80%.
        </p>
        </div>`,
    },
    section3: {
        title: 'We are contributing to carbon neutrality',
        desc: `As the global focus on climate change continues to increase in recent years, 
        ever more investors are looking at the climate impact and climate risk of their investment activities 
        and seeking to invest their money in a way that is consistent with the global goal of addressing climate change. 
        We assess the climate-friendly attributes of investment products in terms of whether they support climate solution-based economic 
        activities and whether they support fossil fuel-related economic activities. By making our findings publicly available, 
        we are promoting the development of climate investment and finance to help China achieve its carbon peak and neutrality commitments.
        `,
        btn1: 'Learn more about the project',
        methods1: `<p>Climate Solution</p>
        <p>55 activities that we consider to be eligible for climate solution attributes</p>
       `,
        btn2: 'Learn more',
        methods2: `<p>Fossil fuel-related</p>
        <p>we have set two criteria for defining fossil fuel-related economic activities</p>
       `,
    },
    section4: {
        someTips1: "Investment products included in the scope of screening:",
        title1: 'Total size of funds',
        title2: 'Number of funds',
        title3: 'Total size of bonds',
        title4: 'Number of bonds',
        fundTotal: '315',
        unit1: ' billion yuan',
        bondNumber: '299',
        unit2: '',
        bondTotal: '31,44',
        unit3: ' billion yuan',
        fundNumber: '1,620',
        unit4: '',
        linkText1: 'View more climate-friendly funds',
        linkText2: 'Screening method',
        linkText3: 'View more climate-friendly bonds',
        linkText4: 'Screening method',
    },
    section5: {
        legalNotice: 'Legal Notice',
        content: `The information, tools and materials presented on the Website are for general information purposes only and should not be considered as an offer to buy or 
        sell any financial product, nor as a formal confirmation of any transaction, unless otherwise explicitly agreed. The information, tools and materials presented on 
        the Website are not intended to provide advice of any kind, including, but not limited to, investment, tax, accounting or legal advice. Investment involves risks and 
        past returns of a fund are not indicative of its future performance. Please read the product prospectus and its official announcement and related information published 
        in the designated information disclosure media carefully before making any investment decisions and be prepared to bear your own investment risks.`,
        more: 'See our full disclaimer'
    },

    homeFooter: {
        publicAccount: 'Scan the QR code to follow us',
        aboutPro: 'About',
        contact: 'Contact Us',
        link: 'GSG Website',
        suggest: 'Suggestion',
        legal: 'Legal',
        email: 'Email',
        address: 'Address',
        copyright: 'CopyRight © 鼎力可持续数字科技（深圳）有限公司'
    },
    homeTabList: [
        { label: "Shenzhen", value: 1 },
        { label: "Beijing", value: 2 },
        { label: "Shanghai", value: 3 },
        { label: "HongKong", value: 4 },
    ],
    homeTabContents: [
        {
            label: "Shenzhen",
            email: "inquiry@governance-solution.com",
            address: "Room 2410, Shenzhen CBD Landmark, No. 4028 Jintian Road, Shenzhen, China",
            value: 1,
        },
        {
            label: "Beijing",
            email: "inquiry@governance-solution.com",
            address: "Room 1101-3, China Hewlett Packard Building, No. 112 Jianguo Road, Chaoyang District, Beijing",
            value: 2,
        },
        {
            label: "Shanghai",
            email: "inquiry@governance-solution.com",
            address: "12th Floor, China Resources Times Plaza, No. 500 Zhangyang Road, Pudong New Area, Shanghai",
            value: 3,
        },
        {
            label: "HongKong",
            email: "inquiry@governance-solution.com",
            address: "16/F, Hong Kong Club Building, 3A Standard Chartered Road, Central, Hong Kong",
            value: 4,
        },
    ],
    elevator: {
        connect1: 'Contact Email',
        connect2: 'Contact WeChat ',
    },
    // 联系邮件-弹窗
    connectDia: {
        name: 'Name:',
        placeholderName: 'Please input your name',
        company: 'Company:',
        placeholderCompany: 'Please input your company',
        role: 'Role:',
        placeholderRole: 'Please input your role',
        phone: 'Phone:',
        placeholderPhone: 'Please input your phone',
        email: 'Email:',
        placeholderEmail: 'Please input your email',
        emailSubject: 'Email Subject:',
        placeholderSubject: 'Please input email subject',
        content: 'Message Content:',
        placeholderContent: 'Please input message content',
        cancel: 'Cancel',
        submit: 'Send',
        errorMsg1: 'This is a required field',
        errorMsg2: 'Please enter the correct email address',
        successMsg1: 'Sent successfully',
        sendingMsg: 'Sending...'
    }
}
// FAQ

export default {
    faqTop: {
        title: 'Q&A'
    },
    faqSection1: {
        title: "About the Methods",
        title1: "1.What is a climate solution company?",
        content1: `A company is defined as a "climate solution" company if its main business meets at least one of the 55 climate-friendly economic activities that we recognize, and its climate solution activities account for more than 50% of its revenue.`,

        title2: "2.Does Climate solution have any restrictions on fossil fuel related business?",
        content2: `The definition of "climate solution" company in this project does not restrict fossil fuel-related businesses, but in determining whether a fund is climate-friendly, a combination of the fair value of the fund's holdings in "climate solution" companies and the holdings of listed companies involved in fossil fuel businesses are examined.`,

        title3: "3.What is the breakdown of the Climate Friendly Solutions business as a percentage of total operating income?",
        content3: `
        Based on the company's reported main business revenue by industry/product, each segment of the main business is assessed on its "climate-friendliness", 
        and the proportion of operating revenue of the climate-friendly business envelopes.
        
        `,

        title4: "4.Why does the definition of climate-friendly bonds require a zero percentage of fossil fuel-related financial uses, while the definition of climate-friendly funds sets a threshold of 5% fair value share of fossil fuel-related companies?",
        content4: `<p>The climate-friendly characteristics of each publicly offered securities investment  fund are aggregated from the attributes of its entire portfolio of underlying companies. Considering the large number of investment targets in the portfolio and the current stage of energy transition in China, we currently do not forbid fossil fuel investment in our definition of climate-friendly funds, but rather <strong>give a threshold limit of 5%</strong>. However, for bond products, each bond product represents a fund raising by its issuer based on specific business or project needs, and our definition of climate-friendly bonds requires a percentage of zero fossil fuel-related fund use, which means that these raised funds cannot be used for fossil fuel-related businesses and projects.
        </p>`,
    },
    faqSection2: {
        title: "Other Information",
        title1: "5.What's the meaning of the three types of fund product?",
        content1: `<div>1) Equity fund</div>
                   <p>A fund that adopts an active investment strategy and invests more than 80% of the fund's.</p>
                   <div>2) Index fund</div>
                   <p>Funds that adopt a passive investment strategy to track a specific index, including but not limited to ETFs.</p>
                   <div>3) Mixed fund</div>
                   <p>Funds that do not meet the above definitions of "equity funds" and "index funds" and in which more than 50% of 
                   the fund's assets are invested in equities.</p>
                   `,
        title2: "6.What is the meaning of the three types of bond product?",
        content2: `<div>1) Enterprise bond</div>
                   <p>Enterprise bonds refer to securities issued by domestic enterprises with the status of legal persons in accordance with legal procedures and agreed to repay the principal and interest within a certain period. Enterprise bonds are generally issued by central government agencies, wholly state-owned enterprises or state-controlled enterprises, and are ultimately approved by the National Development and Reform Commission.</p>
                   <div>2) Corporate bond</div>
                   <p>A corporate bond is a bond issued by a corporation to raise financing for a variety of reasons such as to ongoing operations, M&A, 
                   or to expand business. The term is usually applied to longer-term debt instruments, with maturity of at least one year.
                   </p>
                   <div>3) Short-term Commercial Paper</div>
                   <p>Short-term Commercial Papers are unsecured short-term promissory notes issued by enterprises. Oil price bonds issued and traded in the inter-bank bond market according to the conditions and procedures of the "Guidelines on Rules for the Issuance of Debt Financing Instruments of Non-Financial Enterprises in the Inter-bank Market" and agreed to repay the principal and interest within a certain period are enterprise financing.</p>
                   <div>4) Medium term note</div>
                   <p>Medium term notes refer to notes with maturities usually 5-10 years ahead. The company will usually issue medium term notes through a flexible issuing mechanism arranged by the undertaking manager. Through a single issuance plan, the company will issue notes with different maturities multiple times.</p>
                   <div>5) Government-backed bond</div>
                   <p>Government-backed bonds are one kind of agency bonds. China Railway construction bonds issued by the Ministry of Railways are identified as "government-backed bonds".</p>
                  `,
        title3: "7.What is the meaning of the three types of issuing target for bond products?",
        content3: `
                <div>1) Domestic legal persons and natural persons</div>
                <p>A legal person in the territory is an organization with civil rights and civil capacity, which enjoys civil rights and undertakes civil obligations 
                independently in accordance with the law. It includes a combined group of persons or property organized for a special purpose. A domestic natural person is 
                a natural person who has the nationality of the People's Republic of China.</p>
               
                <div>2) Qualified institutional investors</div>
                <p>a) Financial institutions (including securities companies, fund management companies and their subsidiaries, futures companies, commercial banks, 
                    insurance companies and trust companies, etc.) established with the approval of the relevant financial regulatory authorities.</p>
                <p>b) Financial products issued by the above-mentioned financial institutions for investors.</p>
                <p>c) Qualified Foreign Institutional Investor (QFII), RMB Qualified Foreign Institutional Investor (RQFII).</p>
                <p>d) Enterprises and institutions with net assets of not less than RMB 10 million and partnerships.</p>
                <p>e) Individual investors with financial assets of not less than RMB3 million in their name.</p>
                
                <div>3) Members of the interbank bond market</div>
                <p>a) Commercial banks with legal personality in China and their authorized branches.</p>
                <p>b) Non-bank financial institutions and non-financial institutions with legal personality in China.</p>
                <p>c) Branches of foreign banks authorized by the People's Bank of China to conduct RMB business.</p>
        `,
        title4: "8.What distribution channels are available for public fund products?",
        content4: `
                <p>1) Direct selling by fund companies</p>
                <p>2) Underwritten by bank</p>
                <p>3) Underwritten by fund distribution companies</p>
                <p>4) Underwritten by securities companies</p>
                <p>5) Underwritten by futures companies</p>
                <p>6) Underwritten by insurance companies</p>
                <p>7) Underwritten by securities investment advisory companies</p>
        `,

        title5: "9.What channels are available for overseas investors to buy domestic publicly offered funds?",
        content5: `
                <p>1) Mutual Bond Market Access</p>
                <p>2) Bond Connect</p>
                <p>3) Mutual Recognition of Publicly Offered Securities Investment Funds (for retail investors)</p>
                <p>4) Cross-boundary Wealth Management Connect Scheme (for retail investors)</p>
               `,

        title6: "10.What channels are available for overseas investors to buy domestic bonds?",
        content6: ` <p>1) Settlement agency model: Application for filing with the People's Bank of China, which needs to indicate the bond settlement agent.</p>
                    <p>2) Bond Connect: Domestic and foreign investors can buy and sell tradable bonds from both markets through the Bond Connect.</p>
                    <p>3) Shanghai-Hong Kong Stock Connect: The connectivity mechanism for trading in the Shanghai and Hong Kong stock markets.</p>
                    <p>4) Shenzhen-Hong Kong Stock Connect: The connectivity mechanism for trading in the Shenzhen and Hong Kong stock markets.</p>
                    <p>5) Shanghai-London Stock Connect: The connectivity mechanism between the Shanghai Stock Exchange and the London Stock Exchange.</p>`,
    }
}









<template>
  <div class="app-container">
    <!--顶部通栏-->
    <NavBar />

    <main class="app-body">
      <!-- 二级路由 -->
      <router-view></router-view>
    </main>

    <!-- 底部-->
    <AppFooter />
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import AppFooter from "./components/Footer.vue";
export default {
  name: "Layout",
  components: { NavBar, AppFooter },
};
</script>
<style scoped lang='scss'>
.app-body {
  // min-height: 800px;
  min-height: calc(100vh - 120px);
  margin-top: 70px;
  text-align: initial;
}
</style>